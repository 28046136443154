import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import useWpData from "../Hook/useWpData";
import useCavomData from "../Hook/useCavomData";
import TableValeurPoint from "./TableValeurPoint";
import TableDroitsAcquis from "./TableDroitsAcquis";
import { Table } from 'react-bootstrap';

const frenchFormatter = new Intl.NumberFormat("fr-FR", {minimumFractionDigits: 2});
const postId = 155; // ID Page Retraite WP

function MaRetraite(props) { 

  const { fetchWpPages } = useWpData();
  const { fetchCavomData } = useCavomData();

  const [postData, setPostData] = useState('');
  const [maSituationRetraite, setMaSituationRetraite] = useState();
  const [tabRBPointValueData, setTabRBPointValueData] = useState('');
  const [tabRCPointValueData, setTabRCPointValueData] = useState('');
  const [tabIDPointValueData, setTabIDPointValueData] = useState('');
  const [indexTabRBPointValueData, setIndexTabRBPointValueData] = useState(0);
  const [indexTabRCPointValueData, setIndexTabRCPointValueData] = useState(0);
  const [indexTabIDPointValueData, setIndexTabIDPointValueData] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [isRBFetching, setIsRBFetching] = useState(false);
  const [isRCFetching, setIsRCFetching] = useState(false);
  const [isIDFetching, setIsIDFetching] = useState(false);

  let blocIntroMaRetraite = '';
  let blocExpliMaRetraite = '';
  let blocHistoriqueVP = '';

   const queryParams = new URLSearchParams(window.location.search);
   const selectedQuarter = queryParams.get('quarter');

    console.log(">>>>>>>>>>" + selectedQuarter);



  if (postData && postData.ACF){
    if (postData.ACF.intro_retraite)
    blocIntroMaRetraite = postData.ACF.intro_retraite;
    if (postData.ACF.expli_retraite)
    blocExpliMaRetraite = postData.ACF.expli_retraite;
    if (postData.ACF.historique_vp)
    blocHistoriqueVP = postData.ACF.historique_vp;
  } 

  useEffect(async () => {
    setPostData(await fetchWpPages(postId));
  }, [])

    useEffect(async () => {
      setIsFetching(true);
      Promise.all([
                setMaSituationRetraite(await fetchCavomData(`api/member/pension/details?period=${selectedQuarter}`))
            ])
        .catch(ex => console.error(ex));}, [selectedQuarter])

  useEffect(async () => {
    setIsFetching(true);
    Promise.all([
          setTabRBPointValueData(await fetchCavomData(`api/member/points/value?regime=RB&page=${indexTabRBPointValueData}`)),
          setTabRCPointValueData(await fetchCavomData(`api/member/points/value?regime=RC&page=${indexTabRCPointValueData}`)),
          setTabIDPointValueData(await fetchCavomData(`api/member/points/value?regime=ID&page=${indexTabIDPointValueData}`))
        ]).then(() => {setIsFetching(false); setIsRBFetching(false);setIsRCFetching(false);setIsIDFetching(false)})
          .catch(ex => console.error(ex));
  }, [indexTabRBPointValueData, indexTabRCPointValueData, indexTabIDPointValueData])

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

//    setTabRecapCavomData(await fetchCavomData(`api/member/points/value`));

        return(
        <>
          <div className="bandeau-header col-12">
            <div className="container" style={{ backgroundImage: "url(images/background-presentation-accueil.jpg)" }}>
              <div className="row">
                <h1>Ma Retraite</h1>
              </div>
            </div>
          </div>  
          <div className="container page-ma-retraite cavom-center-content">
            <div className="row">
          <section className="col-12 ma-situation"> 
          <div className="row">
            
            {blocIntroMaRetraite &&
              <>
              <div className="presentation col-12" dangerouslySetInnerHTML={ {__html: blocIntroMaRetraite} } />
              </>
            }
            {maSituationRetraite &&
            <>
           <h2 className="col-12"> <center>Ma situation au {(selectedQuarter == "CURRENT") ? "trimestre courant" : "trimestre précédent"}</center>
             {(selectedQuarter == "PREVIOUS") &&
             <>
              <Link className="rp-link slick-arrow slick-next" to="/ma-retraite?quarter=CURRENT">Consulter le détail</Link>
             </>
             }
              {(selectedQuarter == "CURRENT") &&
              <>
              <Link className="rp-link slick-arrow slick-prev" to="/ma-retraite?quarter=PREVIOUS">Consulter le détail</Link>
              </>
              }
            </h2>
            <div className="col-md-6">
              <div className="col-12 blue-tab tableau-situation" style={{ backgroundImage: "url(images/background-blue-table.jpg)" }}>
                <div className="col-12 first-row">
                  <div className="row">
                    <div className="col-lg-4 col-md-12 wrapper-infos">
                      <h4>Ma <span>retraite</span></h4>
                      <p className="amount">{maSituationRetraite.total ? frenchFormatter.format(maSituationRetraite.total) : 'N/A'} € <span>brut</span></p>
                    </div>
                    <div className="col-lg-4 col-md-6 wrapper-infos">
                      <h4>Retraite <span>de base</span></h4>
                      <p className="amount">{(maSituationRetraite.rb && maSituationRetraite.rb.amount) ? frenchFormatter.format(maSituationRetraite.rb.amount) : 'N/A'} € <span>brut</span></p>
                      <p className='sub-label'>{(maSituationRetraite.rb && maSituationRetraite.rb.totalPoint) ? frenchFormatter.format(maSituationRetraite.rb.totalPoint) : 'N/A'} pts</p>
                    </div>
                    <div className="col-lg-4 col-md-6 wrapper-infos">
                      <h4>Retraite <span>complémentaire</span></h4>
                      <p className="amount">{(maSituationRetraite.rc && maSituationRetraite.rc.amount) ? frenchFormatter.format(maSituationRetraite.rc.amount) : 'N/A'} € <span>brut</span></p>
                      <p className='sub-label'>{(maSituationRetraite.rc && maSituationRetraite.rc.totalPoint) ? frenchFormatter.format(maSituationRetraite.rc.totalPoint) : 'N/A'} pts</p>
                    </div>
                  </div>
                </div>
                <div className="col-12 second-row">
                  <div className="row">
                    <h4 className="col-12">Montant des prélèvements sociaux</h4>
                    <div className="col-md-4">
                      <p className="amount">{(maSituationRetraite.socialDeductions && maSituationRetraite.socialDeductions.CGS) ? frenchFormatter.format(maSituationRetraite.socialDeductions.CGS) : 'N/A'} €</p>
                      <p className='sub-label'>CGS</p>
                    </div>
                    <div className="col-md-4">
                      <p className="amount">{(maSituationRetraite.socialDeductions && maSituationRetraite.socialDeductions.CRDS) ? frenchFormatter.format(maSituationRetraite.socialDeductions.CRDS) : 'N/A'} €</p>
                      <p className='sub-label'>CRDS</p>
                    </div>
                    <div className="col-md-4">
                      <p className="amount">{(maSituationRetraite.socialDeductions && maSituationRetraite.socialDeductions.CASA) ? frenchFormatter.format(maSituationRetraite.socialDeductions.CASA) : 'N/A'} €</p>
                      <p className='sub-label'>CASA</p>
                    </div>
                  </div>
                </div>
                <div className="col-12 third-row">
                  <div className="row">
                    <h4 className="col-12">Montant du prélèvement à la source</h4>
                    <div className="col-12">
                      <p className="amount">{maSituationRetraite.taxAmount ? frenchFormatter.format(maSituationRetraite.taxAmount) : 'N/A'} €</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="offset-md-1 col-md-5">

            <Link to="/documents" className="cavom-button mr-0 ml-0 w-100 text-center dark-blue-button">Accéder à mes documents</Link>
            </div>
            </>
            }
            {blocExpliMaRetraite &&
              <>
              <div className="col-12 presentation-bottom" dangerouslySetInnerHTML={ {__html: blocExpliMaRetraite} } />
              </>
            }
            </div> 
          </section>
          <div className="container droits-acquis cavom-center-content">
            <div className="row">

                <section className="recap-droits col-12">

                    {blocHistoriqueVP &&
                      <>
                      <h2>Historique de la valeur du point</h2>
                      <div className="presentation" dangerouslySetInnerHTML={ {__html: blocHistoriqueVP} } />

                      <header class="tdb-actus-header"><h3>Régime de base</h3></header>
                      {tabRBPointValueData &&
                      <div className="slick-table-wrapper">
                      <TableValeurPoint tabRecapCavomData={tabRBPointValueData} indexTabRecapCavomData={indexTabRBPointValueData} setIndexTabRecapCavomData={setIndexTabRBPointValueData} isFetching={isRBFetching} />
                      </div>
                    }

                    <header class="tdb-actus-header"><h3>Régime complémentaire</h3></header>
                    {tabRCPointValueData &&
                      <div className="slick-table-wrapper">
                      <TableValeurPoint tabRecapCavomData={tabRCPointValueData} indexTabRecapCavomData={indexTabRCPointValueData} setIndexTabRecapCavomData={setIndexTabRCPointValueData} isFetching={isRCFetching} />
                      </div>
                    }

                    <header class="tdb-actus-header"><h3>Régime Invalidité-décès</h3></header>
                    {tabIDPointValueData &&
                    <div className="slick-table-wrapper">
                    <TableValeurPoint tabRecapCavomData={tabIDPointValueData} indexTabRecapCavomData={indexTabIDPointValueData} setIndexTabRecapCavomData={setIndexTabIDPointValueData} isFetching={isIDFetching} />
                    </div>
                    }

                    </>
                    }
                </section>
            </div>
          </div>

        </div>
       </div>
        </>
      )
  }


export default MaRetraite;
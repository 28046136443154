import React, { useContext, useState } from "react";
import useCavomData from "./useCavomData";

// Stocke les données du contexte d'authentification
const AuthContext = React.createContext();

/**
 * Renvoie l'utilisateur enregistré dans le cache
 * (pour le moment on utilise le localStorage  du navigateur)
 */
function getUserFromCache() {
  return JSON.parse(localStorage.getItem("user"));
}

/**
 * Enregistre l'utilisateur dans le cache
 * (pour le moment on utilise le localStorage du navigateur)
 */
function storeUserInCache(user) {
  return localStorage.setItem("user", JSON.stringify(user));
}

/**
 * Supprime l'utilisateur enregistré dans le cache
 * (pour le moment on utilise le localStorage du navigateur)
 */
function removeUserFromCache() {
  return localStorage.removeItem("user");
}

/**
 * Provider fournissant les méthodes + données de l'utilisateur courant
 */
const AuthProvider = (props) => {
  const [user, setUser] = useState(getUserFromCache());
  const { sendCavomData } = useCavomData();

  /**
   * Envoi une requête d'authentification vers l'API CAVOM pour récupérer un token
   */
  async function login(username, password) {
    const token = await sendCavomData("api/accounts/connexion", {
      username,
      password,
    });

    
    // Création de l'utilisateur courant
    // (pour le moment on ne stocke que le token et le username)
    const user = {
      token: token.token,
      username: username,
    };
    
    // console.log(token)
    // throw ('stop')
    
    // Mise à jour des information de l'utilisateur courant
    setUser(user);
    
    // Enregistre le user dans le localStorage
    storeUserInCache(user);
  }

  /**
   * Déconnecte l'utilisateur courant
   */
  function logout() {
    setUser(null);
    removeUserFromCache();
  }

  return <AuthContext.Provider value={{ login, logout, user }} {...props} />;
};

/**
 * Hook permettant d'accéder au contexte d'authentification
 */
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth, getUserFromCache };

// Composant Mes Prélèvements
import React from 'react';
import { Link } from "react-router-dom";
import useCavomData from "../../Hook/useCavomData";

const frenchFormatter = new Intl.NumberFormat("fr-FR", {minimumFractionDigits: 2});

function MesPrelevements({cotiEnCours, memberSchedule}) {

  const { fetchCavomData } = useCavomData();

  async function openPdfBlob(e) {
    const pdfId = e.target.getAttribute("docuid");

    const pdfData = await fetchCavomData(`api/member/publish/${pdfId}`, {
      headers: {
        Accept: "application/pdf",
        "Content-Type": "text/plain",
      }}
    )

    const file = new Blob([pdfData], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }


      return (
          <div className="mes-prelevements mensualise gray-box">
            <h2 id="mes-prelevements" className="toggle-title"><a data-toggle="collapse" href="#collapsePrelevements" role="button" aria-expanded="false" aria-controls="collapseExample">Mes prélèvements</a></h2>
            
            <div id="collapsePrelevements" className="container contentCollapse show">
              <div className="row">
                <div className="col-12">
                  <div className="gray-table-wrapper">
                    <h3>Mes mensualités</h3>
                   { (memberSchedule.length > 0) &&                                        
                    <table>
                      <thead>
                        <tr>
                        <th>Date</th>
                          <th>Montant</th>
                          <th>Etat de l'échéance</th>
                        </tr>
                      </thead>
                      <tbody>
                  
                      {memberSchedule.map( (item,i) =>{
                        return (
                        <tr key={i}>
                        <td>{item.deadlineDate}</td>
                        <td className="amount-td"><span className="amount-left">{frenchFormatter.format(item.amount)} €</span></td>
                        <td>{ item.state == 'EN_COURS' ? 'En cours' : 'Soldée' }</td>
                        </tr>
                          )                  
                      })}                      
                      </tbody>
                      </table>
                    }

                    <a className="cavom-button dark-blue-button download-icon dl-pdf" docuid='LEVY_SCHEDULE' onClick={openPdfBlob}>Télécharger mon échéancier</a>

                  </div>
                </div>
                { /*
                <div className="col-md-5 offset-md-1">
                  <div className="montant_coti">
                    <h3>Montant de la cotisation</h3>
                    <p>{frenchFormatter.format(cotiEnCours.amount)} €</p>
                  </div>
                  <div className="montant_paye">
                    <h3>Montant payé</h3>
                    <p>{frenchFormatter.format(cotiEnCours.settledAmount)} €</p>
                  </div>
                  <div className="reste_payer striped-bg" style={{ backgroundImage: "url(images/striped-bg-tile.jpg)" }}>
                    <div className="white-shadow-bg">
                      <h3>Reste à payer</h3>
                      <p>{frenchFormatter.format(cotiEnCours.amount - cotiEnCours.settledAmount)} €</p>
                    </div>
                  </div>
                */ }
                {/*  <a className="cavom-button download-icon dl-pdf" docuid='PAYMENT_RECEIPT' onClick={openPdfBlob} download>Télécharger mon attestation de paiement</a>

                </div>*/}
              </div>
            </div>
          </div>
      )
    
}

export default MesPrelevements;

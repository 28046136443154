import React from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

import { useAuth } from "../Hook/useAuth";

function Header() {
  const { user, logout } = useAuth();

  return (
    <div className="header container-fluid">
      <div className="row">
      
        <div className="site-logo col-md-2">
          <Link to="/" className="home-link">
            <img src="images/cropped-logo.png" alt="Portail Cavom" />
          </Link>
        </div>
        <div className="col-md-8 d-flex align-items-center mt-md-0 mb-md-0 mt-4 mb-4">
          <ul className="navigation">
            {/* <li>
              <NavLink exact activeClassName="current" to="/">
                Accueil
              </NavLink>
            </li> */}

            {user ? (
              <>
                {/* <li>
                  <NavLink exact activeClassName="current" to="/mes-cotisations">
                  Page Mes Cotisations
                  </NavLink>
                </li> */}

                <NavLink exact activeClassName="current" to="/page-profil">
                  <li>Profil</li>
                </NavLink>

                <li>
                  <NavLink exact activeClassName="current" to="/tableau-de-bord">
                  Tableau de bord
                  </NavLink>
                </li>

                {/* <li>
                  <NavLink exact activeClassName="current" to="/preparer-ma-retraite">
                    Préparer ma retraite
                  </NavLink>
                </li> */}

                {/* <li>
                  <NavLink exact activeClassName="current" to="/demander-ma-retraite">
                    Demander ma retraite
                  </NavLink>
                </li> */}
{
/*                <li>
                  <NavLink exact activeClassName="current" to="/mes-droits-acquis">
                    Mes droits acquis
                  </NavLink>
                </li>
*/}
                {/* <li>
                  <NavLink exact activeClassName="current" to="/ma-retraite">
                    Ma Retraite
                  </NavLink>
                </li> */}
                {/* <li>
                  <NavLink exact activeClassName="current" to="/formulaire-global">
                    Formulaire Global
                  </NavLink>
                </li> */}

                {/* <li>
                  <NavLink exact activeClassName="current" to="/reinitialisation-password">
                    Réinit Password
                  </NavLink>
                </li> */}
                <li>
                  <NavLink exact activeClassName="current" to="/documents">
                    Documents
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink exact activeClassName="current" to="/modification-password">
                    Modification Password
                  </NavLink>
                </li> */}
              </>
            ) : (
              <>

                {/* <li>
                  <NavLink exact activeClassName="current" to="/login/employeur">
                  Login Employeur
                  </NavLink>
                </li> */}

                <li>
                  <NavLink exact activeClassName="current" to="/inscription/affilie">
                    Inscription
                  </NavLink>
                </li>

                <li>
                  <NavLink exact activeClassName="current" to="/login/affilie">
                  Connexion
                  </NavLink>
                </li>

                {/* <li>
                  <NavLink exact activeClassName="current" to="/reinitialisation-password">
                    Réinit Password
                  </NavLink>
                </li> */}

                {/* <li>
                  <NavLink
                    exact
                    activeClassName="current"
                    to="/inscription/employeur"
                  >
                    Inscription Employeur
                  </NavLink>
                </li> */}
              </>
            )}

            {user && (
              <>
            <li>
              <NavLink exact activeClassName="current" to="/actualites">
                Actualités
              </NavLink>
            </li>

            <li>
              <NavLink exact activeClassName="current" to="/faq">
              FAQ
              </NavLink>
            </li>
              </>
            )}
          </ul>

        </div>
          {user && (
          <div className="col-md-2 d-flex align-items-center mt-md-0 mb-md-0 mt-4 mb-4">
                <Link className="current" to="/logout">
                Déconnexion
                </Link>
          </div>
            )}
      </div>
    </div>
  );
}

export default Header;

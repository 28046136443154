import parse from "html-react-parser";

function EmailChange({ register, handleSubmit, watch, formState: { errors }, email }) {
  return (
    <>
    <div className="form-group">
      <label htmlFor="email" className="w-100">Renseigner votre nouvelle adresse mél</label>
      <input
        type="text"
        id="email"
        name="email"
        defaultValue={email && email.contactEmail}
        ref={register({
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Adresse mél non valide"
          }
        })}
        className="col-lg-6 col-md-8"
        placeholder="ex : ex@exemple.fr"
        />
        {errors.email && <span className="form-error" role="alert">{parse(errors.email.message)}</span>}
    </div>

    </>
  );
}

export default EmailChange;
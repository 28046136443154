// Page Profil
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

// import useWpData from "../Hook/useWpData";
import useCavomData from "../Hook/useCavomData";
import { data } from "jquery";

const postId = 55;

function PageProfil(props) {
  // const { fetchWpPages } = useWpData();
  // const [postData, setPostData] = useState('');
  const { fetchCavomData, sendCavomData } = useCavomData();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [data_mon_identite, setMonId] = useState("");
  const [data_moyens_paiement, setMoyensPaiement] = useState("");
  const [data_option_com, setOptionCom] = useState("");
  const [consentment, setConsentment] = useState("");
  const [data_beneficiaires, setBeneficiares] = useState("");
  const [contentieux, setContentieux] = useState("");
  const statutClient = "normal"; // retraité / employeur
  const [statutModifOptionsCom, setStatusModifOptionsCom] = useState("");
  const [loadingOptionsCom, setLoadingOptionsCom] = useState(false);
  const [popupDeleting, setPopupDeleting] = useState(false);
  const [beneficiaryName, setBeneficiaryName] = useState('');
  const [beneficiaryId, setBeneficiaryId] = useState('');
  const [beneficiaryFirstName, setBeneficiaryFirstName] = useState('');
  const [postErrors, setPostErrors] = useState();
  const [postSuccess, setPostSuccess] = useState();
  const [postErrorsBeneficiary, setPostErrorsBeneficiary] = useState();
  const [isSubmittingBeneficiary, setIsSubmittingBeneficiary] = useState(false);
  // const [defaultContact, setDefaultContact] = useState('');


  // let presentation = '';
  // if (postData){
  //   presentation = postData.ACF.presentation;
  // }

  // A la soumission du formulaire global
  const onSubmitOptionCom = async (data) => {
    setLoadingOptionsCom(true);
    setStatusModifOptionsCom("");

    if (data) {
      // Création du motif attendu par l'API CAVOM
      const comData = {
        newsletter: data.profilNewsletter,
        notifications: data.profilNotifications,
        electronicDeclaration: data.profilDeclarationElec,
      };

      // Envoie des data sur l'API CAVOM
      await sendCavomData("api/account/communication-options", comData);

      setLoadingOptionsCom(false);
      setStatusModifOptionsCom(data.error ? "ERROR" : "SUCCESS");
    }
  };

  useEffect(async () => {
    // setPostData(await fetchWpPages(postId));

    setMonId(await fetchCavomData("api/member/extended"));
    setMoyensPaiement(await fetchCavomData("api/member/payment_method"));
    setBeneficiares(await fetchCavomData("api/member/beneficiaries"));
    setContentieux(await fetchCavomData("api/member/summary"));
    setOptionCom(await fetchCavomData("api/account/communication-options"));
    setConsentment(await fetchCavomData("api/consentment/"));
    // setDefaultContact(await fetchCavomData("api/consentment/"));
  }, []);

  async function handleDefaultContact (e) {
    setPostSuccess(false)
    //Envoie des data sur l'API CAVOM
    try {

      const data = await sendCavomData(`api/member/contactAddress/${e.target.value}`);
      console.log(data)
      if (data.com6responsestatus == 202){
        setPostSuccess('Votre demande de changement de coordonnées de contact a bien été envoyée');
      }
    } catch (e) {
        console.error(e);
        setPostErrors(e.message);
    }
  }

  const openPopupDeleting = (e) => {
    setPopupDeleting(true)
    setPostSuccess(false)
    setBeneficiaryFirstName(e.target.getAttribute("beneficiaryfirstname"))
    setBeneficiaryId(e.target.getAttribute("beneficiaryid"))
    setBeneficiaryName(e.target.getAttribute("beneficiaryname"))
  }

  const closePopupDeleting = () => {
    setPopupDeleting(false)
    setPostErrorsBeneficiary(false)
  }
  const deleteBeneficiary = async (e) => {
    setIsSubmittingBeneficiary(true)
    const idTodelete = e.target.getAttribute("beneficiaryid")
    try {
      const data = await sendCavomData(`api/beneficiary/${idTodelete}`,{}, {method: "DELETE", headers: {Accept: "*/*"}});
      if (data.com6responsestatus == 200){
        setBeneficiares(await fetchCavomData("api/member/beneficiaries"));
        setPostSuccess('Votre demande de suppression de bénéficiaire a bien été envoyée');
        setPopupDeleting(false)
      }
    } catch (e) {
        console.error(e);
        setPostErrorsBeneficiary('Une erreur est survenue');
    }
    setIsSubmittingBeneficiary(false)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [postErrors, postSuccess])

  return (
    <>
      <section className="page-profil">
        <div className="bandeau-header">
          <div
            className="container"
            style={{
              backgroundImage:
                "url(images/background-presentation-accueil.jpg)",
            }}
          >
            <div className="row">
              <h1>
                Bonjour
                {data_mon_identite && data_mon_identite.firstname
                  ? " " + data_mon_identite.firstname
                  : ""}
                {data_mon_identite && data_mon_identite.name
                  ? " " + data_mon_identite.name
                  : ""}
              </h1>
            </div>
          </div>
        </div>
        <div className="container page-profil-infos cavom-center-content">
        {contentieux.litigation && (
          <p className="profil-contentieux alert-danger p-3 row">
            Votre dossier est en contentieux
          </p>
        )}
        {postErrors && (
          <p className="alert alert-danger row" role="alert">
            { postErrors }
          </p>
        )}
        {postSuccess && (
          <p className="alert alert-success row" role="alert">
            { postSuccess }
          </p>
        )}
          <div className="row">
            <div className="col-md-7 left-box">
              {data_mon_identite && (
                <div className="cavom-identity bottom-dotted">
                  <h2>Mon identité</h2>
                  <ul>
                    {data_mon_identite.memberNumber && (
                      <li>
                        Identifiant CAVOM :
                        <span className="strong">
                          {data_mon_identite.memberNumber}
                        </span>
                      </li>
                    )}
                    {data_mon_identite.adhesionEntranceDate &&
                     (
                        <li>
                          Date d'affiliation :
                          <span className="strong">
                            {data_mon_identite.adhesionEntranceDate}
                          </span>
                        </li>
                      )}
                        {data_mon_identite.memberNumber && (
                                            <li>
                                              Date de radiation :
                                              <span className="strong">
                                                {data_mon_identite.adhesionExitDate ? data_mon_identite.adhesionExitDate: "N/A"}
                                              </span>
                                            </li>
                        )}
                    {data_mon_identite.status && (
                      <li>
                        Statut :
                        <span className="strong">
                          {data_mon_identite.status}
                        </span>
                      </li>
                    )}
                    {data_mon_identite.name && (
                      <li>
                        Nom :
                        <span className="strong">{data_mon_identite.name}</span>
                      </li>
                    )}
                    {data_mon_identite.firstname && (
                      <li>
                        Prénom :
                        <span className="strong">
                          {data_mon_identite.firstname}
                        </span>
                      </li>
                    )}
                    {data_mon_identite.birthdate && (
                      <li>
                        Date de naissance :
                        <span className="strong">
                          {data_mon_identite.birthdate}
                        </span>
                      </li>
                    )}
                    {data_mon_identite.socialSecurityNumber && (
                      <li>
                        Numéro de sécurité sociale :
                        <span className="strong">
                          {data_mon_identite.socialSecurityNumber}
                        </span>
                      </li>
                    )}
                    {data_mon_identite.profession && (
                      <li>
                        Profession :
                        <span className="strong">
                          {data_mon_identite.profession}
                        </span>
                      </li>
                    )}
                    {data_mon_identite.familySituation && (
                      <li>
                        Situation familiale :
                        <span className="strong">
                          {data_mon_identite.familySituation}
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              )}
              {data_mon_identite && (
                <div className="coordonnees">
                  <h2>Mes coordonnées personnelles</h2>
                  {data_mon_identite.privateAddress && data_mon_identite.privateAddress != null &&                   
                  <div>
                    {data_mon_identite.privateAddress && data_mon_identite.privateAddress.sendingAddress == true 
                    ? <><input type="radio" id="defautlPerso" name="defaultContactDetails" value={data_mon_identite.privateAddress.addressId}  onChange={handleDefaultContact} defaultChecked/>
                    <label htmlFor="defautlPerso">Coordonnées de contact choisies</label></>
                    : <><input type="radio" id="defautlPerso" name="defaultContactDetails" value={data_mon_identite.privateAddress.addressId} onChange={handleDefaultContact}/>
                    <label htmlFor="defautlPerso">Coordonnées de contact choisies</label></>                    
                    }
                  </div>
                  }
                  <ul className="coord-perso bottom-dotted">
                    <li>
                      Adresse postale personnelle :
                      <span className="strong">
                        {data_mon_identite.privateAddress &&
                        data_mon_identite.privateAddress.oneLineAddress
                          ? data_mon_identite.privateAddress.oneLineAddress
                          : "non définie"}
                      </span>

                    <Link  className="iconmoon-pencil" to={{

                        pathname: "/formulaire-global",
                        state: {
                            address : data_mon_identite.privateAddress,
                            formulaire: "ADDRESS_CHANGE",
                            type: "PERSONAL"
                            }
                           }}>
                         {/*data_mon_identite.privateAddress && data_mon_identite.privateAddress != null ?  "Modifier mon"  :"Ajouter une" } adresse*/ }
                           </Link>
                           </li>

                    {data_mon_identite.email && data_mon_identite.email.contactEmail && (
                      <>
                      <li>
                        Mél personnel :
                        <span className="strong">
                          {data_mon_identite.email.contactEmail}
                        </span>

                      <Link   className="iconmoon-pencil"
                        to={{
                            pathname: "/formulaire-global",
                            state: {
                                email : data_mon_identite.email,
                                formulaire: "EMAIL_CHANGE",
                                type: "PERSONAL"
                                }
                               }} />

                       </li>
                      </>
                    )}
                     {data_mon_identite.privateAddress && data_mon_identite.privateAddress != null &&
                    <li>
                      Téléphone personnel :
                      <span className="strong">
                        {data_mon_identite.privateAddress &&
                        data_mon_identite.privateAddress.phone
                          ? data_mon_identite.privateAddress.phone
                          : "non défini"}
                      </span>
                      <Link   className="iconmoon-pencil"
                         to={{
                              pathname: "/formulaire-global",
                              state: {
                                  phone : data_mon_identite.privateAddress.phone,
                                  formulaire: "PHONE_NUMBER_CHANGE",
                                  type: "PERSONAL"
                                  }
                           }} />
                    </li>
                    }
                  </ul>
                  <h2>Mes coordonnées professionnelles</h2>
                  {data_mon_identite.professionalAddress && data_mon_identite.professionalAddress != null && 
                  <div>
                    {data_mon_identite.professionalAddress.sendingAddress == true 
                    ? <><input type="radio" id="defautlPro" name="defaultContactDetails" value={data_mon_identite.professionalAddress.addressId} onChange={handleDefaultContact} defaultChecked/>
                    <label htmlFor="defautlPro">Coordonnées de contact choisies</label></>
                    : <><input type="radio" id="defautlPro" name="defaultContactDetails" value={data_mon_identite.professionalAddress.addressId} onChange={handleDefaultContact}/>
                    <label htmlFor="defautlPro">Coordonnées de contact choisies</label></>                    
                    }
                    
                  </div>
                  }
                  <ul className="coord-pro">
                    <li>
                      Adresse postale professionnelle :
                      <span className="strong">
                        {data_mon_identite.professionalAddress &&
                        data_mon_identite.professionalAddress.oneLineAddress
                          ? data_mon_identite.professionalAddress.oneLineAddress
                          : "non définie"}
                      </span>

                    <Link className="iconmoon-pencil" to={{
                        pathname: "/formulaire-global",
                        state: {
                            address : data_mon_identite.professionalAddress,
                            formulaire: "ADDRESS_CHANGE",
                            type: "PROFESSIONAL"
                            }
                           }}/>
                     </li>
                      {data_mon_identite.professionalAddress && data_mon_identite.professionalAddress != null &&
                    <li>
                      Téléphone professionnel :
                      <span className="strong">
                        {data_mon_identite.professionalAddress &&
                        data_mon_identite.professionalAddress.phone
                          ? data_mon_identite.professionalAddress.phone
                          : "non défini"}
                      </span>
                      <Link  className="iconmoon-pencil" to={{
                         pathname: "/formulaire-global",
                         state: {
                             phone: data_mon_identite.professionalAddress.phone,
                             formulaire: "PHONE_NUMBER_CHANGE",
                             type: "PROFESSIONAL"
                             }
                            }}/>
                    </li> }
                  </ul>
                </div>
              )}
            </div>
            <div className="col-md-5 right-box">
        {/*      <div
                className="deconnexion-box col-12 striped-bg"
                style={{ backgroundImage: "url(images/striped-bg-tile.jpg)" }}
              >
                <Link
                  to="/logout"
                  className="deconnexion cavom-button dark-blue-button"
                >
                  Se déconnecter
                </Link>
              </div>*/ }
              <div
                className="password-box blue-box"
                style={{
                  backgroundImage: "url(images/background-blue-table.jpg)",
                }}
              >
                <h2>Mon mot de passe</h2>
                <Link
                  to="/modification-password"
                  className="modif-password cavom-button white-button"
                >
                  Modifier
                </Link>
              </div>
              {data_moyens_paiement && (
                <div className="moy-paiement-box col-12 gray-box">
                  <h2>Mes moyens de paiement</h2>
                  <p>
                    Type :
                    <span className="strong">{data_moyens_paiement.type == "CHEQUE" ? "TIP" : data_moyens_paiement.type }</span>
                  </p>
                  {data_moyens_paiement.type == 'PRELEVEMENT' && data_moyens_paiement.iban && (
                    <p>
                      IBAN :
                      <span className="strong">
                        {data_moyens_paiement.iban}
                      </span>
                    </p>
                  )}
                  {
                    data_moyens_paiement.type == 'PRELEVEMENT' &&
                  <Link className="iconmoon-pencil" to={{
                                                 pathname: "/formulaire-global",
                                                 state: {
                                                     rib : data_moyens_paiement,
                                                     formulaire: "BANK_ACCOUNT",
                                                     type: ""
                                                     }
                                                    }}>
                  </Link>
                  }
                </div>
              )}
              {data_option_com && (
                <div className="options-com-box col-12 gray-box">
                  <h2>Mes options de communication</h2>
                  <form onSubmit={handleSubmit(onSubmitOptionCom)}>
                    <p className="checkbox-wrapper">
                      <input
                        type="checkbox"
                        name="profilNewsletter"
                        id="profilNewsletter"
                        defaultChecked={
                          data_option_com.newsletter ? "checked" : ""
                        }
                        ref={register}
                      />
                      <label htmlFor="profilNewsletter">Newsletter</label>
                    </p>
                    <p className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      name="profilNotifications"
                      id="profilNotifications"
                      defaultChecked={
                        data_option_com.notifications ? "checked" : ""
                      }
                      ref={register}
                    />
                    <label htmlFor="profilNotifications">Notifications</label>
                    </p>
                    <p className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      name="profilDeclarationElec"
                      id="profilDeclarationElec"
                      defaultChecked={
                        data_option_com.electronicDeclaration ? "checked" : ""
                      }
                      ref={register}
                    />
                    <label htmlFor="profilDeclarationElec">
                      Déclaration électronique
                    </label>
                    </p>

                    <input
                      type="submit"
                      className="cavom-button"
                      value="Modifier"
                    />
                  </form>
                  {loadingOptionsCom && (
                    <div className="sending-data">Envoie en cours...</div>
                  )}
                  {statutModifOptionsCom == "SUCCESS" && (
                    <div className="modif-success">
                      Votre demande de modification a bien été transmise
                    </div>
                  )}
                  {statutModifOptionsCom == "ERROR" && (
                    <div className="modif-success">
                      Une erreur s'est produite dans l'envoie de votre
                      modification
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <section
          className="blue-banner-bottom"
          style={{ backgroundImage: "url(images/background-blue-bottom.jpg)" }}
        >
          <div
            className="blue-banner-corner"
            style={{
              backgroundImage: "url(images/background-blue-bottom-2.jpg)",
            }}
          >
            &zwnj;
          </div>
          {data_beneficiaires && (
            <div className="container beneficiaires">
              <div className="row">
                <h2>Mes bénéficiaires</h2>
                <table>
                  <tbody>
                    {data_beneficiaires.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.firstname} {item.name}</td>
                          <td>{item.type}</td>
                          <td>{item.birthdate}</td>
                          <td>{item.nir ? item.nir : 'null'}</td>
                          <td><span className="delete-beneficiary icon-cancel" beneficiaryid={item.id} beneficiaryname={item.name} beneficiaryfirstname={item.firstname} onClick={openPopupDeleting}></span></td>
                        </tr>
                      )
                    })
                    }
                </tbody>
                </table>
                <Link className="cavom-button white-button"
                    to={{
                      pathname: "/formulaire-global",
                       state: {
                        formulaire: "BENEFICIARY_CHANGE",

                        }
                      }}>Ajouter</Link>
              </div>
            </div>
          )}
          {popupDeleting &&

          <div className='compopup delete-popup'>
            <div className='compopup_inner cavom-center-content'>
              <div className="row">
              <h2 className="col-12">Suppression d'un bénéficiaire</h2>
              <p className="col-12">Vous allez supprimer le bénéficiaire suivant : </p>
              <p className="nom col-12"><strong>Nom : </strong>{beneficiaryName}</p>
              <p className="prenom col-12"><strong>Prénom : </strong>{beneficiaryFirstName}</p>
              <button className="cavom-button m-3" onClick={deleteBeneficiary} beneficiaryid={beneficiaryId} disabled={isSubmittingBeneficiary}>Confirmer</button>
              <button className="cavom-button m-3" onClick={closePopupDeleting} >Annuler</button>
              </div>

              {postErrorsBeneficiary && (
                <p className="alert alert-danger col-12" role="alert">{postErrorsBeneficiary}</p>
              )}

            <span onClick={closePopupDeleting} className="close-popup"><i className="icon-cancel"></i></span>
            </div>
          </div>
          }
        </section>
      </section>
    </>
  );
}

export default PageProfil;

import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import parse from "html-react-parser";
import useCavomData from "../../Hook/useCavomData";

import AccordionGutenberg from '../../Assets/AccordionGutenberg';

function FormReinitPasswordProfil ({setComposantSelect, aideModifPassword, faqModifPassword}) {
  
  const { sendCavomData } = useCavomData();
  const [connexionError, setConnexionError] = useState();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    errors,
    watch
  } = useForm();

  const password = useRef({});
  password.current = watch("password", "");

  
  const onSubmit = async ({ password, passwordRepeat }) => {
    const modifPassword = {
      newPassword: password
    }
    setConnexionError(null);
    if (password === passwordRepeat){
      console.log('meme password')
      try {
        const response = await sendCavomData('api/change-password', modifPassword);
        console.log(response)
        if (response.com6responsestatus == 202){
          setComposantSelect('SendScreen')
        }
              
      } catch (e) {
        window.scroll({
          top: 200,
          left: 0,
          behavior: 'smooth',
        });
        
        setConnexionError(e.message);
      }
    }else{
      setConnexionError('Votre mot de passe ne correspond pas')
    }
  };

  useEffect(() => {
    AccordionGutenberg();
  });
  
  return (
    <section
    className="modif-pwd"
    >
      <div className="container cavom-center-content">
        <div className="row">
          <div className="col-md-5">
            <h2>Modifier votre mot de passe</h2>

            <form onSubmit={handleSubmit(onSubmit)}>

              {connexionError && <p className="alert alert-danger" role="alert" dangerouslySetInnerHTML={{ __html: connexionError }} />}

              <label>
                <span className="sr-only">Nouveau mot de passe :</span>
              </label>
              <input
                type="password"
                name="password"
                ref={register({
                   required: true,
                   pattern: {
                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/,
                    message: "Votre mot de passe doit contenir au moins : <ul><li>1 majuscule</li> <li>1 minuscule</li> <li>1 chiffre</li> <li>1 caractère spécial</li> <li>et au minimum 8 caractères au total</li></ul>"
                   } 
                  })}
                placeholder="Nouveau mot de passe"
                />
                {errors.password && <span className="form-error" role="alert">{parse(errors.password.message)}</span>}

              <label>
                <span className="sr-only">Confirmer votre mot de passe :</span>
              </label>
              <input
                type="password"
                name="passwordRepeat"
                ref={register({
                  required: true,
                  validate: (value) =>
                    value === password.current ||
                    "Le mot de passe ne correspond pas.",
                })}
                placeholder="Confirmer votre mot de passe"
                />

                {errors.passwordRepeat && (
                <p className="form-error" role="alert">{errors.passwordRepeat.message}</p>
              )}

                <input
                  type="submit"
                  disabled={isSubmitting}
                  className="cavom-button"
                  value="Valider"
                  />
            </form>
          </div>

{/*
          <div className="aide-connexion offset-md-2 col-md-5">
            <h2 className="help-icon">Aide</h2>
            {faqModifPassword &&
            <div className="faq-wrapper">
            {
            faqModifPassword.map((item, i) => {

              return(                     
                <article key={i} className="faq-result">
                <div className="wp-block-pb-accordion-item c-accordion__item js-accordion-item" data-initially-open="false" data-click-to-close="true" data-auto-close="false" data-scroll="false" data-scroll-offset="0">
                <h3 id={`at-${item.ID}`} className="c-accordion__title js-accordion-controller" role="button" tabIndex="0" aria-controls={`ac-${item.ID}`} aria-expanded="false" dangerouslySetInnerHTML={{ __html: item.post_title}} />
                <div id={`ac-${item.ID}`} className="c-accordion__content" hidden="hidden"><p dangerouslySetInnerHTML={{ __html: item.post_content}} />
                </div>
                </div>
                </article> 
              )              
            })
            }   
            </div>
            }
            <div
              className="aide-affilie"
              dangerouslySetInnerHTML={{ __html: aideModifPassword }}
              />
          </div>
          */}
        </div>
      </div>
    </section>
  );
}

export default FormReinitPasswordProfil;
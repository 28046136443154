import React from 'react';

function DemandeEnvoyee ({postData}) {

  let texteDemandeEnvoyee = '';
  if (postData && postData.ACF){
    texteDemandeEnvoyee = postData.ACF.texte_demande_envoyee
  }
  return(
    <div className="container create-notif demande-envoyee">
      <div className="row">
        <div className="col-12">
          <h2>Demande Envoyée</h2>
          <p className="subtitle">Un lien d'activation vous a été adressé par mél</p>
          {texteDemandeEnvoyee &&
          <p className="txt-expli-notif" dangerouslySetInnerHTML={ {__html: texteDemandeEnvoyee} } />
          }
          <a href="http://www.cavom.fr" className="cavom-button">Lien vers www.cavom.fr</a>
        </div>
      </div>
    </div>
  )

}

export default DemandeEnvoyee;
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../Hook/useAuth";

/**
 * Route privée. Redirige vers l'accueil si l'utilisateur n'est pas connecté
 */
const PrivateRoute = (props) => {
  const { user } = useAuth();

  if (user) {
    return <Route {...props} />;
  }

  return <Redirect to="/" />;
};

export default PrivateRoute;

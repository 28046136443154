import { post } from "jquery";
import React, { useState, useEffect } from "react";
import useWpData from "../Hook/useWpData";
import AccordionGutenberg from '../Assets/AccordionGutenberg';
import { useAuth } from "../Hook/useAuth";
import { Link, Redirect } from "react-router-dom";
import ErrPage from './PortailInaccessible/ErrPage';

function Accueil() {
  const accueil_id = "13";
  const { fetchWpPages } = useWpData();
  const [postData, setPostData] = useState('');
  const { login, user } = useAuth();
  
  let presentation_accueil = '';
  if (postData){
    presentation_accueil = postData.ACF.presentation_accueil;
  }

  useEffect(async () => {
    setPostData(await fetchWpPages(accueil_id));
  }, []);

  useEffect(() => {
    AccordionGutenberg();
  });

  const showError = false;

  // Si l'utilisateur est connecté on redirige
  if (user) {
    return <Redirect to="/tableau-de-bord" />;
  }

  return (
    <section className="section-presentation-accueil">
      <div className="bandeau-header">
        <div className="container" style={{ backgroundImage: "url(images/background-presentation-accueil.jpg)" }}>
          <div className="row">
            <div id="accueil-portail" className="col-12 ecran-accueil d-flex flex-wrap">
              <div className="presentation-accueil d-flex flex-wrap">
                <p>Bienvenue sur le portail de la CAVOM</p>
              </div>
            </div>
          </div>
        </div>
      </div>
     {  showError && (<ErrPage/>) }
      <div className="acces-portail-connexion">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-5" dangerouslySetInnerHTML={{ __html: presentation_accueil }} />
            <div className="connexion col-lg-6">
              <div className="connexion-acces connexion-affilie" style={{ backgroundImage: "url(images/background-acces-affilie.jpg)" }}>
                <h2>Mon espace personnel</h2>
                <Link to="/login/affilie" className="cavom-button button-accueil">
                  Accéder
                </Link>
              </div>
            </div>

            {1 == 0 &&
              <div className="connexion col-lg-6">
              <div  className="connexion-acces connexion-employeur" style={{ backgroundImage: "url(images/background-acces-employeur-2.jpg)" }}>
                <h2>Mon compte employeur</h2>
                <Link to="/login/employeur" className="cavom-button green-button">
                  Accéder
                </Link>
              </div>
            </div>
            }

          </div>
        </div>
      </div>
    </section>
  );
}

export default Accueil;

import parse from "html-react-parser";


function PhoneNumber({ register, handleSubmit, watch, formState: { errors }, phone }) {
  return (
    <>
    <div className="form-group">
      <label htmlFor="telephone_perso" className="w-100">Entrez votre nouveau numéro de téléphone</label>
      <input
        defaultValue={phone}
        type="text"
        id="telephone_perso"
        name="telephone_perso"
        ref={register({
          pattern: {
            value: /^\+?[0-9]*$/,
            message: 'Chiffres uniquement autorisés',
          }
        })}
        className="col-lg-6 col-md-8"
        placeholder="ex : 06 06 06 06 06 06"
        />
        {errors.telephone_perso && <span className="form-error" role="alert">{parse(errors.telephone_perso.message)}</span>}
    </div>

    </>
  );
}

export default PhoneNumber;
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Table } from "react-bootstrap";

function TableDroitsAcquisOld ({tabRecapCavomData}) {

  const frenchFormatter = new Intl.NumberFormat("fr-FR");

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

  };

  let arrayTables = [];
  let temporaryTable = [];
  const dataLength = tabRecapCavomData.memberPoints.length;

  tabRecapCavomData.memberPoints.map( (item,i) => {
    temporaryTable.push(item);
    if (((i+1) % 3 == 0) || ((i+1) == dataLength) ){
      arrayTables.push(temporaryTable);
      temporaryTable=[];
    }
  })

  return (
    <>    
    <Slider {...settings}>                    
        {
        arrayTables && arrayTables.map( (items, is) => {
        return (
            <React.Fragment key={is}>
              <table>
                <thead>
                  <tr>
                    <th className="year">Année</th>
                    <th>Régimes</th>
                    <th>Points</th>
                  </tr>
                </thead>
                <tbody>
              {items.map((item,i) => {
                return (
                    <tr key={i}>
                    <td className="year">{item.year}</td>
                    <td>
                    { (item.planStatus == 'RB' && 'Retraite de base')
                    || (item.planStatus == 'RC' && 'Retraite complémentaire')
                    }
                    </td>
                    <td className="points">{frenchFormatter.format(item.totalPoint)} pts</td>                    
                    </tr>
                )
              })}
              </tbody>
            </table>
          </React.Fragment>
        )
        
       })
    }
    </Slider>
    </> 
  )
}

export default TableDroitsAcquisOld;
import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

const frenchFormatter = new Intl.NumberFormat("fr-FR", {minimumFractionDigits: 2});

function SituationDemarches ( {MaSituation, MesDemarches, RetraiteProfil} ) {

  return(
    <div className="col-12 situation-demarches">
    <div className="row">
      <div className="ma-situation col-md-6">
        <h2>Ma situation</h2>
        <Tabs transition={false} defaultActiveKey="en-cours" id="uncontrolled-tab-example">
          <Tab eventKey="en-cours" title="Activité">
            <div className="blue-tab" style={{ backgroundImage: "url(images/background-blue-table.jpg)" }}>
              <h3>{MaSituation.year - 1 }/{MaSituation.year}</h3>
              <table className="table-retraite">
                <thead>
                  <tr>
                    <th>Montant appelé</th>
                    <th>Solde restant dû </th>
                    <th>Prochaine échéance<br/>{MaSituation && MaSituation.nextDeadline && MaSituation.nextDeadline.deadlineDate ? MaSituation.nextDeadline.deadlineDate : ''}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{MaSituation && (typeof MaSituation.amount === 'number') ? frenchFormatter.format(MaSituation.amount) : 'N/A'} €</td>
                    <td>{MaSituation && (typeof MaSituation.settledAmount === 'number') ? frenchFormatter.format(MaSituation.amount - MaSituation.settledAmount) : 'N/A'} €</td>
                    <td> {MaSituation && MaSituation.nextDeadline && (typeof MaSituation.nextDeadline.amount === 'number') ? frenchFormatter.format(MaSituation.nextDeadline.amount - MaSituation.nextDeadline.amountPayed) : 'N/A'} €</td>
                  </tr>
                </tbody>
              </table>
              <div className="tdb-mode-paiement">
                <p>Mode de paiement :<br/><strong>{MaSituation && MaSituation.typePayment ? (MaSituation.typePayment == "CHEQUE" ? "TIP" : MaSituation.typePayment ) : 'Type'}</strong></p>
                <Link className="cavom-button dark-blue-button zoom-icon" to="/mes-cotisations">Mes cotisations</Link>
              </div>
              <div className="tdb-contact">
                <p>Contact : {MaSituation && MaSituation.emailSummary && MaSituation.emailSummary.contactEmail ? MaSituation.emailSummary.contactEmail : 'Adresse mél de contact'}</p>
              </div>
            </div>
          </Tab>
          {RetraiteProfil &&
          <Tab eventKey="retraite" title="Retraite">
            <div className="blue-tab col-12" style={{ backgroundImage: "url(images/background-blue-table.jpg)" }}>
              <div className="row">
                <div className="col-lg-6">
                  <h3>Mon prochain paiement</h3>
                  <div className="inner-next-payment">
                    <p className="amount-payment"><span className='strong'>{(MaSituation && MaSituation.pensionDto && MaSituation.pensionDto.next && (typeof MaSituation.pensionDto.next.amount === 'number') ) ? frenchFormatter.format(MaSituation.pensionDto.next.amount) : 'N/A'} €</span></p>
                    {MaSituation && MaSituation.pensionDto && MaSituation.pensionDto.next && MaSituation.pensionDto.next.date &&
                      <p className="date-payment">Versé le {MaSituation.pensionDto.next.date}</p>
                    }
                  </div>
                  <Link className="rp-link" to="/ma-retraite?quarter=PREVIOUS">Consulter le détail</Link>
                </div>
                <div className="col-lg-6">
                  <h3>Mon précédent paiement</h3>
                  <div className="inner-prev-payment">
                    <p className="amount-payment"><span className='strong'>{(MaSituation && MaSituation.pensionDto && MaSituation.pensionDto.last && (typeof MaSituation.pensionDto.last.amount === 'number')) ? frenchFormatter.format(MaSituation.pensionDto.last.amount) : 'N/A'} €</span></p>
                    {MaSituation && MaSituation.pensionDto && MaSituation.pensionDto.last && MaSituation.pensionDto.last.date &&
                      <p className="date-payment">Versé le {MaSituation.pensionDto.last.date}</p>
                    }
                  </div>
                    <Link className="rp-link" to="/ma-retraite?quarter=CURRENT">Consulter le détail</Link>
                </div>
              </div>
            </div>
          </Tab> }
          {/* <Tab eventKey="annule" title="Annulé">
          <div className="blue-tab" style={{ backgroundImage: "url(images/background-blue-table.jpg)" }}>
            <p>Content tab 3</p>
          </div>
          </Tab> */}
        </Tabs>
      </div>
      {MesDemarches &&
      <div className="mes-demarches col-md-6">
        <h2>Mes démarches</h2>
        <ul className="mes-demarches-liste">
          {MesDemarches.AFFILIER_RADIER_SALARIE &&
            <li><Link to="/">Radier salarié</Link></li>
          }
          {MesDemarches.DECLARER_REVENUS &&
            <li><Link  to={{ pathname: "/formulaire-global", state: { formulaire: "ESTIMATED_REVENUE"} }}> Déclarer mes revenus</Link></li>
          }
          {MesDemarches.MES_DROITS &&
                                <li><Link to="/mes-droits-acquis">Consulter mes droits</Link></li>
                    }
           {MesDemarches.MES_COTISATIONS &&
                     <li><Link to="/mes-cotisations">Consulter mes cotisations</Link></li>
                     }
          {/*MesDemarches.DECLARER_REVENUS_SALARIE &&
            <li><Link to="/">Déclarer revenu salarié</Link></li>
          */}
           {MesDemarches.DEMANDER_RETRAITE &&
                      <li><Link to="/demander-ma-retraite">Demander ma retraite</Link></li>
                    }
          {
            <li><HashLink to="/mes-cotisations#echeancier-de-paiement">Payer mes cotisations</HashLink></li>
          }



          {/*MesDemarches.MES_DOCUMENTS &&
            <li><Link to="/documents">Mes documents</Link></li>*/
          }

          {/*MesDemarches.PREPARER_RETRAITE &&
            <li><Link to="/preparer-ma-retraite">Préparer ma retraite</Link></li>*/
          }
           <li><Link
                      to={{
                      pathname: "/formulaire-global",
                      state: {
                      formulaire: "GENERAL_REQUEST"
                      }
                   }}>Formuler une demande</Link></li>
        </ul>
      </div>
      }
    </div>
  </div>
  )
}

export default SituationDemarches;
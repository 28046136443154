import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import parse from "html-react-parser";
import useCavomData from "../../Hook/useCavomData";

function FormReinitPassword ({setComposantSelect, apitoken}) {
  
  const { sendCavomData } = useCavomData();
  const [connexionError, setConnexionError] = useState();
    const [passwordShown, setPasswordShown] = useState(false);
      const togglePassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShown(!passwordShown);
      };

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    errors,
    watch
  } = useForm();

  const password = useRef({});
  password.current = watch("password", "");

  
  const onSubmit = async ({ password, passwordRepeat }) => {
    const modifPassword = {
      token: apitoken,
      newPassword: password
    }
    setConnexionError(null);
    if (password === passwordRepeat){
      try {
        const response = await sendCavomData('api/t/password-change-with-token', modifPassword);
        console.log(response)
        if (response.com6responsestatus == 200){
          setComposantSelect('SendNewPassword')
        }
              
      } catch (e) {
        window.scroll({
          top: 200,
          left: 0,
          behavior: 'smooth',
        });
        
        setConnexionError(e.message);
      }
    }else{
      setConnexionError('Votre mot de passe ne correspond pas')
    }
  };
  
  return (
    <section
    className="reinit-pwd"
    >
      <div className="bandeau-header">
        <div className="container">
          <div className="row">
            <h1>Mot de passe</h1>
          </div>
        </div>
      </div>

      <div className="container cavom-center-content">
        <div className="row">
          <div className="col-md-6">
            <h2>Réinitialisez votre mot de passe</h2>

            <form onSubmit={handleSubmit(onSubmit)}>

              {connexionError && <p className="alert alert-danger" role="alert" dangerouslySetInnerHTML={{ __html: connexionError }} />}

              <label>
                <span className="sr-only">Nouveau mot de passe :</span>
              </label>
              <input
                type={passwordShown ? "text" : "password"}
                name="password"
                ref={register({
                   required: true,
                   pattern: {
                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/,
                    message: "Votre mot de passe doit contenir au moins : <ul><li>1 majuscule</li> <li>1 minuscule</li> <li>1 chiffre</li> <li>1 caractère spécial</li> <li>et au minimum 8 caractères au total</li></ul>"
                   } 
                  })}
                placeholder="Nouveau mot de passe"
                />
                {errors.password && <span className="form-error" role="alert">{parse(errors.password.message)}</span>}

              <label>
                <span className="sr-only">Confirmer votre mot de passe :</span>
              </label>
              <input
                type={passwordShown ? "text" : "password"}
                name="passwordRepeat"
                ref={register({
                  required: true,
                  validate: (value) =>
                    value === password.current ||
                    "Le mot de passe ne correspond pas.",
                })}
                placeholder="Confirmer votre mot de passe"
                />
                <input type="checkbox" id="showpassword" name="showpassword"  onClick={togglePassword} />
                            <label for="showpassword">Afficher le mot de passe</label>

                {errors.passwordRepeat && (
                <p className="form-error" role="alert">{errors.passwordRepeat.message}</p>
              )}

                <input
                  type="submit"
                  disabled={isSubmitting}
                  className="cavom-button"
                  value="Valider"
                  />
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FormReinitPassword;
import { getUserFromCache } from "./useAuth";

/**
 * URL de l'API CAVOM
 */
// const CAVOM_API = "http://frontity-cavom-portail.com6-interactive.eu"; // si on ne passe en direct par api cavom
const CAVOM_API = "https://api.cavom.fr/api/v1"; // Si URL choisie, penser à supprimer api/ en début de requete (voir ligne 84)


// const CAVOM_API = "https://kube.cavom.lan/front";

export default function useCavomData() {
  /**
   * Retourne les options pour toutes les requêtes vers l'API.
   * Merge les options passées si nécéssaire.
   * Gère l'ajout du token d'authentification si nécéssaire.
   */
  const getRequestOptions = (customOptions) => {
    const options = {
      ...customOptions,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    const user = getUserFromCache();

    if (user) {
      options.headers.Authorization = `Bearer ${user.token}`;
    }

    if (customOptions.headers) {
      options.headers = {
        ...options.headers,
        ...customOptions.headers,
      };
    }

    return options;
  };

  /**
   * Traite les retours des requête vers l'API
   */
  const handleRequestResponse = async (res) => {
    // On déconnecte si l'utilisateur n'est pas autorisé et que l'on appelle pas l'url de login
    if (res.status === 401 && !/accounts\/connexion/.test(res.url)) {
      return (window.location = "/logout");
    }

    // Récupération des données retournées par l'API
    try {

      const contentType = await res.headers.get("content-type");

      let data = (contentType == 'application/json') ?  await res.json() : {};

      // Si le code HTTP retourné est "acceptable", on renvoie la réponse

      if ([200, 201, 202, 404].includes(res.status)) {
        // if (contentType === 'application/pdf') {
        //   data['com6responsestatus'] = res.status
        // } 
        if (contentType === 'application/pdf') {
          data = await res.blob()
        }else{
          data['com6responsestatus'] = res.status
        }

        return data;
      }

      // Sinon on lève une exception
      throw data;
    } catch (e) {
      throw (e);
    }
  };

  /**
   * Lance une requête vers l'API
   */
  async function requestCavomData(endpoint, options = {}) {
    // #1 a utiliser à la place de ${endpoint} avec un accès direct à l'api cavom
    const endpointSlice = endpoint.replace(/^(api\/)/, ''); 
    const res = await fetch(
      `${CAVOM_API}/${endpointSlice}`,
      getRequestOptions(options)
    );
    // fin de #1
    
    // console.log(`${CAVOM_API}/${endpoint}`)
    // console.log(getRequestOptions(options))
    
    
    // #2 a utiliser si on ne passe pas en direct par l'api cavom
    // const res = await fetch(
      //   `${CAVOM_API}/${endpoint}`,
      //   getRequestOptions(options)
      // );
    // fin de #2

    return await handleRequestResponse(res);
  }

  /**
   * Récupère des données depuis l'API de la CAVOM
   */
  async function fetchCavomData(endpoint, options = {}) {
    return await requestCavomData(endpoint, options);
  }

  /**
   * Envoie des données POST à l'API CAVOM
   */
  async function sendCavomData(endpoint, formData, options = {}) {
    return await requestCavomData(endpoint, {
      method: "POST",
      body: JSON.stringify(formData),
      ...options,
    });
  }

  return {
    fetchCavomData,
    sendCavomData,
  };
}

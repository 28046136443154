import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import Slider from "react-slick";
import parse from 'html-react-parser';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import TableDroitsAcquis from "./TableDroitsAcquis";
import TableDroitsAcquisOld from "./TableDroitsAcquisOld";
import useWpData from "../Hook/useWpData";
import useCavomData from "../Hook/useCavomData";
import { Table } from 'react-bootstrap';

const postId = 155; // ID Page Retraite WP

function MesDroitsAcquisRetraite(props) { 

  const { fetchWpPages } = useWpData();
  const { fetchCavomData } = useCavomData();

  const [postData, setPostData] = useState('');
  const [tabRecapCavomData, setTabRecapCavomData] = useState('');
  const [indexTabRecapCavomData, setIndexTabRecapCavomData] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  let blocRecapCavom = '';
  let blocRecapCarriere = '';

  if (postData && postData.ACF){
    if (postData.ACF.bloc_recapitulatif_cavom)
    blocRecapCavom = postData.ACF.bloc_recapitulatif_cavom;
    if (postData.ACF.bloc_recapitulatif_carriere)
    blocRecapCarriere = postData.ACF.bloc_recapitulatif_carriere;
  } 

  useEffect(async () => {
    setPostData(await fetchWpPages(postId));    
  }, [])

  useEffect(async () => {
    setIsFetching(true)
    setTabRecapCavomData(await fetchCavomData(`api/member/points?page=${indexTabRecapCavomData}`));
    setIsFetching(false)
  }, [indexTabRecapCavomData])

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

      return(
        <>
          <div className="bandeau-header">
            <div className="container" style={{ backgroundImage: "url(images/background-presentation-accueil.jpg)" }}>
              <div className="row">
                <h1>Mes droits acquis</h1>
              </div>
            </div>
          </div>  
          <div className="container droits-acquis cavom-center-content">
            <div className="row">

          <section className="recap-droits col-12">
          {blocRecapCavom &&
            <>
            <h2>Mes droits acquis à la CAVOM</h2>
            <div className="presentation" dangerouslySetInnerHTML={ {__html: blocRecapCavom} } />
            {tabRecapCavomData &&
            <div className="slick-table-wrapper">              
            <TableDroitsAcquis tabRecapCavomData={tabRecapCavomData} indexTabRecapCavomData={indexTabRecapCavomData} setIndexTabRecapCavomData={setIndexTabRecapCavomData} isFetching={isFetching} />            
            {/* <TableDroitsAcquisOld tabRecapCavomData={tabRecapCavomData} /> */}
            </div>
          }

            </>
          }
          </section>
          <section className="carriere-droits col-12">
          {blocRecapCarriere &&
          <>
          <h2>MES DROITS ACQUIS DANS TOUS LES RÉGIMES</h2>
          <div className="presentation" dangerouslySetInnerHTML={ {__html: blocRecapCarriere} } />
          <a href="https://www.info-retraite.fr/portail-services/login" className="cavom-button" target='_blank'>Accéder à mon relevé de carrière complet</a>
          </>
          }
          </section>

          </div>
        </div>           
        </>
      )
  }


export default MesDroitsAcquisRetraite;
import React, { useState, useEffect } from "react";
import useCavomData from "../Hook/useCavomData";
import Pagination from "../GlobalComponents/Pagination";

function ArchiveDocuments(props) {
  const { fetchCavomData } = useCavomData();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [year, setYear] = useState('');
  const [selectYear, setSelectYear] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(12);


  useEffect(async () => {
    setLoading(true);
    setDocuments(await fetchCavomData('api/document/all'));
    setLoading(false);
  }, []);

  useEffect(() => {
    if (documents.length){
      documents.map((document)=>{
        let date = new Date(document.lastUpdated)
        let year = date.getUTCFullYear()
        // let year = date.getUTCMonth() + 1
        if (!selectYear.includes(year)){
          selectYear.push(year)
        }
      })
      selectYear.sort((a,b) =>b - a)
    }
  }, [documents]);


  // met à jour la recherche
  const updateSearch = (event) => {
    setSearch(event.target.value)
    setCurrentPage(1)
  }
  const updateYear = (event) => {
    setYear(event.target.value)
    setCurrentPage(1)
  }

// filtrer les résultats
const tabDocItemFiltered = (documents, search) => {
    
  if(documents == null || documents[0] == undefined )
      {
        return [];
      }

  if ((Object.keys(documents).length !== 0)){   

    const documentsArray = Object.entries(documents)
    
    if ((search != undefined && search != '') || year != ''){
      let documentsArrayTemp = documentsArray
      let documentsArrayYear = []
      //filtrer par date
      if ( year != ''){

        
        const resultYear = documentsArrayTemp.filter(
          (docItem) => {
            if (docItem[1] && docItem[1].lastUpdated && year){
              let dateResult = new Date(docItem[1].lastUpdated)
              let yearResult = dateResult.getUTCFullYear()
              // let yearResult = dateResult.getUTCMonth() + 1
              return(
                yearResult == year
              )
            }
          }
        )
        documentsArrayYear = resultYear
            
      }else{
        documentsArrayYear =  documentsArray
      }
      // filtrer par mots clé
      if (search != undefined && search != ''){
        const result = documentsArrayYear.filter(
          (docItem) => {
            if (docItem[1] && docItem[1].filename && search){
              return (
                docItem[1].filename.toLowerCase().indexOf(search.toString().toLowerCase()) !== -1
                )
              }
           }
        )
        return(
          result
        )
      }else{
        return documentsArrayYear
      }
    }else{
      return documentsArray
    }

  }else{
    return []
  }
}


  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = tabDocItemFiltered(documents, search).slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // formatage de la date du document
  const dateDocuFormat = (data) => {
    const dateDocu = data.toLocaleDateString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  return dateDocu
}


    
  async function openPdf(e) {
    const pdfId = e.target.getAttribute("docuid");

    const pdfData = await fetchCavomData(`api/document/${pdfId}`, {
      headers: {
        Accept: "application/pdf",
        "Content-Type": "text/plain",
      }}
    )

    const file = new Blob([pdfData], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }


  if (loading) {
    return (
      <>
      <div className="bandeau-header">
      <div className="container" style={{ backgroundImage: "url(images/background-presentation-accueil.jpg)" }}>
        <div className="row">
          <h1>Documents</h1>
        </div>
      </div>
    </div>
    <h3>Chargement...</h3>
    </>
    )
  }


  return (
    <section className="archive-docu">
      <div className="bandeau-header">
        <div className="container" style={{ backgroundImage: "url(images/background-presentation-accueil.jpg)" }}>
          <div className="row">
            <h1>Documents</h1>
          </div>
        </div>
      </div>
      <div className="container docu-results">
        <div className="row">
          <div className='col-12 archive-search-form'>
          <input type="search" value={search} onChange={updateSearch} placeholder="Mots-clés" />
          {selectYear &&
          <>
          <div className="select-wrapper">
          <select name="annees" id="year-select" onChange={updateYear}>
          <option value="">Toutes les années</option>
          {
            selectYear.map((item, i)=>{
              return(
                <option value={item} key={i}>{item}</option>
              )
            })
          }
          </select>
          </div>

          </>
          }

        {currentPosts && currentPosts.length == 0 && <div> Aucun document n'est disponible </div>}


          </div>
            {currentPosts.map((item, i) => {
              if (item[0] == "com6responsestatus") return;
              item = item[1]
              const dateDocu = new Date(item.monitoring.createdOn);
              return(
                <div className="single-docu col-lg-3 col-md-4 col-sm-6" key={i}>
                  <div className=" single-docu-wrapper position-relative">
                    <div className="imgdocu-wrapper position-relative"><span><i></i></span></div>
                    <div className="infodocu-wrapper">
                      <div className="up-infodocu d-flex flex-wrap">
                        {item.typeDocument && 
                        <p className='docutype'>{item.typeDocument}</p>
                        }
                        {dateDocu && dateDocuFormat(dateDocu) && dateDocuFormat(dateDocu)!='Invalid Date' &&
                        <p className='docudate'>{dateDocuFormat(dateDocu)}</p>
                        }
                      </div>
                      <h4>{item.filename}</h4>
                      {item.urn && item.urn.uid && 
                      <a onClick={openPdf} docuid={item.urn.uid} className="link-open-docu"><span className="sr-only">Ouvrir le document</span></a>
                      }
                    </div>
                  </div>                
                </div>
              )
            })}
            <Pagination
              postsPerPage={postsPerPage}
              totalPosts={tabDocItemFiltered(documents, search).length}
              paginate={paginate}
              currentPage={currentPage}
              show={currentPosts && currentPosts.length>0}
            />

        </div>
      </div>
    </section>
  );
}

export default ArchiveDocuments;

import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import useWpData from "../Hook/useWpData";
// import parse from 'html-react-parser';

const postId = 155; // ID Page Retraite WP

function DemanderMaRetraite(props) { 

  const { fetchWpPages } = useWpData();

  const [postData, setPostData] = useState('');

  let blocPreparerDossier = '';
  let blocDemanderRetraite = '';
  let blocMesDroits = '';

  if (postData && postData.ACF){
    if (postData.ACF.bloc_comment_preparer_mon_dossier)
    blocPreparerDossier = postData.ACF.bloc_comment_preparer_mon_dossier;
    if (postData.ACF.bloc_demander_ma_retraite)
    blocDemanderRetraite = postData.ACF.bloc_demander_ma_retraite;
    if (postData.ACF.bloc_mes_droits)
        blocMesDroits = postData.ACF.bloc_mes_droits;
  } 

  useEffect(async () => {
    setPostData(await fetchWpPages(postId));
  }, [])
  

        return(
        <div className="page-standard">
          <div className="bandeau-header">
            <div className="container" style={{ backgroundImage: "url(images/background-presentation-accueil.jpg)" }}>
              <div className="row">
                <h1>Demander ma Retraite</h1>
              </div>
            </div>
          </div> 
          <div className="container cavom-center-content">
            <div className="row">
                        <section className="col-12">
                          <h2>Forumler ma demande</h2>
                          {blocDemanderRetraite &&
                          <div className="presentation" dangerouslySetInnerHTML={ {__html: blocDemanderRetraite} } />
                        }
                          <div className="demande-retraite-link">
                            <div className='img-wrapper'></div>
                            <p className='text-wrapper'>Vous pouvez effectuer votre demande en ligne, sur le site du GIP Union Retraite. En principe, toutes vos données de carrière y figurent.</p>
                            <a href="https://www.info-retraite.fr/portail-info/sites/PortailInformationnel/home/demander-ma-retraite-en-ligne.html" className="cavom-button" target='_blank'>Faire ma demande</a>
                          </div>
                          <div className="question-retraite-link">
                            <div className='img-wrapper'></div>
                            <p className='text-wrapper'>Vous avez une question concernant votre retraite ? Vous n'avez pas trouvé votre réponse dans la FAQ, contactez-nous par mél</p>
                            <Link to="/page/253" className="cavom-button">Contactez-nous</Link>
                          </div>
                        </section>
          <section className="col-12">
            <h2>Transmettre mes documents</h2>
            {blocPreparerDossier &&
              <>
              <div className="presentation" dangerouslySetInnerHTML={ {__html: blocPreparerDossier} } />
              <Link to="/formulaire-global?formulaire=RETIREMENT_REQUEST" className="cavom-button">Envoyer mes documents</Link>
              </>
            }
          </section>


                  <section className="col-12">
                  {blocMesDroits &&
                    <>
                    <h2>CONSULTER MES DROITS</h2>
                    <div className="presentation" dangerouslySetInnerHTML={ {__html: blocMesDroits} } />
                    <Link to="/mes-droits-acquis" className="cavom-button">Mes droits acquis</Link>
                    <a href="https://www.info-retraite.fr/portail-services/login" className="cavom-button" target='_blank'>Mon relevé de carrière</a>
                   </>
                  }
                  </section>
          </div>
        </div>            
        </div>
      )
  }


export default DemanderMaRetraite;
import React from "react";
import { Link } from "react-router-dom";

function PaymentStatusCallback ({status}){
 return(
   <>
  <div className="bandeau-header">
    <div className="container" style={{ backgroundImage: "url(images/background-presentation-accueil.jpg)" }}>
      <div className="row">
        <h1>Status du paiment</h1>
      </div>
    </div>
  </div>
   {status == 'accept' &&
      <div className="container create-notif demande-envoyee">
       <div className="row">
         <div className="col-12">
           <h2>Votre paiment a été accepté</h2>
           <Link to="/mes-cotisations" className="cavom-button">Retour</Link>
         </div>
       </div>
     </div>
   }
   {status == 'rejet' &&
      <div className="container create-notif compte-existant">
       <div className="row">
         <div className="col-12">
           <h2>Votre paiment a été refusé</h2>
           <Link to="/mes-cotisations" className="cavom-button">Retour</Link>
         </div>
       </div>
     </div>
   }
   </>
 )
}

export default PaymentStatusCallback;
import React, { useState, useEffect } from 'react';

import useCavomData from "../Hook/useCavomData";
import useWpData from "../Hook/useWpData";

import MesOptionsCotisations from './components/MesOptionsCotisations';
import MesCotisations from './components/MesCotisations';
import MesPrelevements from './components/MesPrelevements';
import MonEcheancierPaiement from './components/MonEcheancierPaiement';
import MesArrieresDePaiement from './components/MesArrieresDePaiement';
import PaymentStatusCallback from './components/PaymentStatusCallback';

const postId = 35; // Id de la page WP

function PageMesCotisations(){


  const { fetchWpPages } = useWpData();
  const { fetchCavomData } = useCavomData();
  const [postData, setPostData] = useState('');
  const [coti_prev_years, setCotiPrevYear] = useState('');
  const [cotisations_en_cours, setCotiEnCours] = useState('');
  const [payment_method_array, setPaymentMethod] = useState('');
  const [member_schedule, setMemberSchedule] = useState('');
  const [member_schedules, setMemberSchedules] = useState('');
  const [contentieux, setContentieux] = useState('');
  const [memberOptions, setMemberOptions] = useState('');
  const [attestationPaiment, setAttestationPaiment] = useState('');
  const [echeancierPrelevement, setEcheancierPrelevement] = useState('');
  const [postErrors, setPostErrors] = useState();
  const [dejaPaye, setDejaPaye] = useState('N/A');
  const [waitingSpinner, setWaitingSpinner] = useState(false);

  let payment_method = payment_method_array && payment_method_array.type ? payment_method_array.type : 'Non défini';
  let presentation = '';
  let expli_option = '';
  let expli_coti = '';

  if (postData){
    presentation = postData.ACF.presentation;    
    expli_option = postData.ACF.expli_coti;    
    expli_coti = postData.ACF.expli_coti_formalites;
  }  

  const queryParams = new URLSearchParams(window.location.search);
  const paymentStatus = queryParams.get('status');

  async function openPdf(e) {
    const pdfId = e.target.getAttribute("docuid");

    const pdfData = await fetchCavomData(`api/document/${pdfId}`, {
      headers: {
        Accept: "application/pdf",
        "Content-Type": "text/plain",
      }}
    )

    const file = new Blob([pdfData], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  async function openPdfYearBlob(e) {
    setWaitingSpinner(e.target.getAttribute("docuid"))
    const pdfId = e.target.getAttribute("docuid");
    
    try{
      const pdfData = await fetchCavomData(`api/member/publish/ASSESSMENT_APPEAL?year=${pdfId}`, {
        headers: {
          Accept: "application/pdf",
          "Content-Type": "text/plain",
        }}
        )
        
        const file = new Blob([pdfData], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        
    }catch (e) {
      setPostErrors("Le document demandé n'est pas encore disponible.");
    }
    setWaitingSpinner(false)
  }

  const closeAlert = () => {
    setPostErrors(false);
  }



  useEffect(async () => {
    setPostData(await fetchWpPages(postId));
    setPaymentMethod(await fetchCavomData('api/member/payment_method'));
    setCotiEnCours(await fetchCavomData('api/member/contribution'));
    setMemberSchedule(await fetchCavomData('api/member/schedule'));
    setMemberSchedules(await fetchCavomData('api/member/schedules'));
    setCotiPrevYear(await fetchCavomData('api/contribution?page=1&size=100'));
    setContentieux(await fetchCavomData('api/member/summary'));
    setMemberOptions(await fetchCavomData('api/contribution/class'));
    setAttestationPaiment(await fetchCavomData('api/member/publish/PAYMENT_RECEIPT'));
    setDejaPaye(await fetchCavomData('api/dashboard/situation'));
    setEcheancierPrelevement(await fetchCavomData('api/member/publish/LEVY_SCHEDULE'));
  }, [])


    // Tri des éléments en attente de paiement
var dataCoti = [];
if (member_schedules){
  member_schedules.map( (item,i) => {
    if (item.state == 'EN_COURS'){
      dataCoti.push(item)
      // item[1].deadlines.map((subitem) => {
        
      //   if (subitem.state == 'EN_COURS'){
      //     dataCoti.push(subitem)
      //   }
      // })
    }
  })
}

useEffect(() => {
  window.scrollTo(0, 0)
}, [postErrors])

  return(
    <>
    {(paymentStatus == 'accept' || paymentStatus == 'rejet')
    ? <PaymentStatusCallback status={paymentStatus} />
    : <section className="page-mes-cotisations">
      <div className="bandeau-header">
        <div className="container" style={{ backgroundImage: "url(images/background-presentation-accueil.jpg)" }}>
          <div className="row">
            <h1>Cotisations</h1>
          </div>
        </div>
      </div>
      <div className="container mes-cotisations-infos">
      {postErrors && (
        <p className="alert alert-danger col-12" role="alert">
          { postErrors }
          <span className="close-alert" onClick={closeAlert}><i className="icon-cancel"></i></span>
        </p>
      )}
        <div className="row">
          <div className="col-12">
            <MesCotisations Presentation={presentation} expliCoti={expli_coti} cotiPrevYear={coti_prev_years} cotiEnCours={cotisations_en_cours} paymentMethod={payment_method} contentieux = {contentieux} fetchCavomData={fetchCavomData}  openPdf={openPdf} openPdfYearBlob={openPdfYearBlob} waitingSpinner={waitingSpinner} dejaPaye={dejaPaye} />
            {payment_method == 'PRELEVEMENT' &&
            <MesPrelevements cotiEnCours={cotisations_en_cours} memberSchedule={member_schedule} openPdf={openPdf} echeancierPrelevement={echeancierPrelevement} />            
            }
            {payment_method != 'PRELEVEMENT' &&
            <MonEcheancierPaiement cotiEnCours={cotisations_en_cours} memberSchedule={member_schedule} contentieux = {contentieux} attestationPaiment={attestationPaiment} />            
            }
            {(dataCoti.length > 0)  &&
              <MesArrieresDePaiement  dataCoti={dataCoti} contentieux = {contentieux} />
            }
            <MesOptionsCotisations expliOption={expli_option} memberOptions={memberOptions} />
          </div>
        </div>
      </div>
    </section>
  }
  </>
  )
}

export default PageMesCotisations;
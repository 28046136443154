import parse from "html-react-parser";
import React, { useRef} from "react";

function ContributionOption({ register, handleSubmit, watch, formState: { errors } }) {
  const target_option = useRef({});
  target_option.current = watch("target_option", "");
  
  return (
    <>
    <div class="presentation"><p>Plusieurs options s’offrent à vous, que vous pouvez modifier en cours de carrière.</p></div>

    <div className="form-group row">

      <div className="col-md-4 d-flex flex-column justify-content-between">
         <label htmlFor="year" className="mb-2 w-100">Année de changement souhaitée *</label>
        <input
          type="text"
          id="year"
          name="year"
          ref={register({
            required: true,
            pattern: {
              value: /^\+?[0-9]*$/,
              message: 'Chiffres uniquement autorisés',
            }
          })}
          placeholder="ex : 2020"
          />
          {errors.year && <span className="form-error" role="alert">{parse(errors.year.message)}</span>}
      </div>
      <div className="col-md-4 d-flex flex-column justify-content-between">

        <label htmlFor="target_option" className="mb-2 w-100">Classe de cotisation souhaitée *</label>
        <select
          type="text"
          id="target_option"
          name="target_option"
          className="form-country"
          ref={register({required: true})}
          >
            <option value="">Choisir une classe</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>

          </select>
      </div>
      <div className="col-md-4 d-flex flex-column justify-content-between">

        <label htmlFor="current_option" className="mb-2 w-100">Classe de cotisation actuelle</label>
        <select
          type="text"
          id="current_option"
          name="current_option"
          className="form-country"
          ref={register({
            required: true,
            validate: (value) =>
                    value !== target_option.current || "La classe souhaitée et la classe actuelle doivent être différentes.",
          })}
          >
            <option value="">Choisir une classe</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>

          </select>
      </div>
        {errors.current_option && <span className="col-12 form-error" role="alert">{parse(errors.current_option.message)}</span>}


    </div>

      
    </>
  );
}

export default ContributionOption;
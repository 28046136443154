import React from 'react';
import { Link } from 'react-router-dom';

const Posts = ({ posts, loading }) => {
  if (loading) {
    return <h3>Chargement...</h3>;
  }

  return (
    <div className="actus-results">
      {
        posts.map((item,i) =>
          <article key={i} className="row d-flex">
            <div className="col-md-5 thumbnail-wrapper">
              {item.featured_image &&
                <img src={item.featured_image} />
              }
            </div>
            <div className="col-md-7 thumbnail-inner pb-5">
              <h3 dangerouslySetInnerHTML={ {__html: item.title.rendered} } />
              <div className="excerpt" dangerouslySetInnerHTML={ {__html: item.excerpt.rendered} } />
              <Link to={`/actualite/${item.id}`} className="fiche-link"><i className="icon-cancel"></i></Link>
            </div>
            <Link to={`/actualite/${item.id}`} className="post-link">&zwnj;</Link>
          </article>
        )
      }
    </div>
  )}

export default Posts;
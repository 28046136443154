import React, { useState, useEffect } from "react";
import useWpData from "../Hook/useWpData";
import Posts from "../GlobalComponents/Posts";
import Pagination from "../GlobalComponents/Pagination";

function ArchiveActus(props) {
  const { fetchWpPosts, fetchWpBase, fetchAllWpPosts } = useWpData();
  const [posts, setPosts] = useState([]);
  const [postsFiltered, setPostsFiltered] = useState([]);
  const [postsCategories, setPostsCategories] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(4);

  useEffect(async () => {
    setLoading(true);
    setPosts(await fetchAllWpPosts());
    // setPosts(await fetchWpPosts());
    setPostsCategories(await fetchWpBase('categories'));
    setLoading(false);
  }, []);


  useEffect( async ()=>{
    if (selectedTheme != ''){
      const tempPostsFiltered = () => {        
        return posts.filter(
          (post)=>{
            return(
              post.categories.includes(parseInt(selectedTheme, 10))
          )
        })
      }
      // console.log(await tempPostsFiltered())
      setPostsFiltered( await tempPostsFiltered() ) 
    }else{
      setPostsFiltered(posts)
      
    }

  }, [posts, postsCategories, selectedTheme])

  const updateThemes = (event) => {
    setSelectedTheme(event.target.value)
    setCurrentPage(1)
  }

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = postsFiltered.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <section className="archive-actus">
      <div className="bandeau-header">
        <div className="container" style={{ backgroundImage: "url(images/background-presentation-accueil.jpg)" }}>
          <div className="row">
            <h1>Actualités</h1>
          </div>
        </div>
      </div>
      <div className="container actus-results">
      {postsCategories &&
        <>
        <div className="archive-search-form">
          <div className="select-wrapper">
          <select name="themes" id="themes-select" onChange={updateThemes}>
          <option value="">Tous les thèmes</option>
          {
            postsCategories.map((item, i)=>{
              return(
                <option value={item.id} key={i}>{item.name}</option>
              )
            })
          }
          </select>
          </div>
          </div>
        </>
        }
        <Posts posts={currentPosts} loading={loading} />
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={postsFiltered.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </div>
    </section>
  );
}

export default ArchiveActus;

import { Link } from "react-router-dom";

function Sidebar({ post }) {
  if (!post.ACF) {
    return null;
  }

  return (
    <div className="col-md-4 sidebar cavom-center-content">
      {post.ACF.articles_lies && post.ACF.articles_lies.length && (
        <div className="actus-side">
          <h3>Actualités</h3>

          <ul className="actus-side">
            {post.ACF.articles_lies.map((item, i) => (
              <li key={i}>
                <h4 dangerouslySetInnerHTML={{ __html: item.post_title }} className="m-0" />
                <Link to={`/actualite/${item.ID}`} className="cavom-button ml-0 mt-3">Voir l'article</Link>
              </li>
            ))}
          </ul>
        </div>
      )}

      {post.ACF.pages_liees && post.ACF.pages_liees.length > 0 && (
        <div className="pages-side">
          <h3>Pages</h3>
          <ul className="pages-side">
            {post.ACF.pages_liees.map((item, i) => (
              <li key={i}>
                <h4 dangerouslySetInnerHTML={{ __html: item.post_title }} className="m-0" />
                <Link to={`/page/${item.ID}`} className="cavom-button ml-0 mt-3">Voir la page</Link>
              </li>
            ))}
          </ul>
        </div>
      )}

      {post && post.ACF.info_plus && (
        <div className="infos-side">
          <h3>Info +</h3>
          <div dangerouslySetInnerHTML={{ __html: post.ACF.info_plus }} />
        </div>
      )}
    </div>
  );
}

export default Sidebar;

import React, { useState, useEffect } from "react";


function GeneralRequest({ register, handleSubmit, watch, formState: { errors }, selectGeneralRequest }) {
  const [subSelectGeneralRequest, setSubSelectGeneralRequest] = useState();
  return (
    <>
      {selectGeneralRequest &&
      <>
        <div className="form-group">
          <div className="select-wrapper">
          <select
            onChange={(e) => {
              setSubSelectGeneralRequest(e.target.value)              
            }

            }
            name="libellesGeneralRequest"
            ref={register({required: true})}
            className="form-select"
            >
              <option value="">Choisir une option</option>
              {selectGeneralRequest.map( (item, i)=> {             
                
                  return (
                    <option value={item.name} key={i} >
                      {item.name}
                    </option>
                  );
              })

              } 

            </select>
            </div>
            </div>
            <div className="form-group">

            {subSelectGeneralRequest && 
            <div className="mb-5 select-wrapper">
              <select
              name="subLibellesGeneralRequest"
              ref={register({required: true})}
              className="form-select"
              >
                <option value="">Choisir une option</option>
                {selectGeneralRequest.map( (item, i)=> {
                  
                  return(
                    <React.Fragment key={i}>
                      {(item.name == subSelectGeneralRequest) 
                        ? item.motifs.map((item, i)=>{
                          return (
                            <option value={item.code} key={i} >
                              {item.name}
                            </option>
                          );

                        })
                        : ''
                      }
                    </React.Fragment>
                  )                
                })  
                } 
  
              </select>   
              </div>         
            }


           
        </div>
        </>
      }        

      <div className="form-group mb-5">
        <label htmlFor="description" className="w-100 d-block">Description de votre demande *</label>
        <textarea
          id="description"
          name="description"
          ref={register({required: true})}
          style={{
            height: '200px',
            width: '500px',
            maxWidth: '100%'
          }}
          />
      </div>


      <div className="form-group row">
        <div className="col-md-6">
        <label htmlFor="intitule" className="w-100 d-block">Intitulé du document</label>
        <input id="intitule" name="intitule" type="text" placeholder="ex: Attestation sur l'honneur" ref={register} />
          <label htmlFor="attachment" className="w-100 d-block">Pièce jointe</label>
          <input
            type="file"
            id="attachment"
            name="attachment"
            ref={register}
            />
              <label className="w-100 d-block">Format accepté : PDF, DOC(X), JP(E)G, PNG, ZIP <br/>Taille maximale : 10MB</label>
        </div>

      </div>
    </>
  );
}

export default GeneralRequest;
import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch, useLocation} from 'react-router-dom';

import { AuthProvider } from './Hook/useAuth';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Accueil from './Accueil/Accueil';
import PageMesCotisations from './PageMesCotisations/PageMesCotisations';
import LoginAffilieEmployeur from './Accueil/LoginAffilieEmployeur';
import InscriptionAffilieEmployeur from './Accueil/InscriptionAffilieEmployeur';
import PageProfil from './PageProfil/PageProfil';
import ArchiveActus from './PageArchives/ArchivesActus';
import ArchiveFaq from './PageArchives/ArchivesFaq';
import ArchiveDocuments from './PageArchives/ArchivesDocuments';
import Logout from './GlobalComponents/Logout';
import PageStandard from './GlobalComponents/PageStandard';
import PrivateRoute from './GlobalComponents/PrivateRoute';
import TableauBordPersonnalise from './TableauBordPersonnalise/TableauBordPersonnalise';
import PreparerMaRetraite from './PageRetraite/PreparerMaRetraite';
import DemanderMaRetraite from './PageRetraite/DemanderMaRetraite';
import MesDroitsAcquisRetraite from './PageRetraite/MesDroitsAcquisRetraite';
import MaRetraite from './PageRetraite/MaRetraite';
import FormCotisationsAPayer from './PageMesCotisations/FormCotisationsAPayer';
import ReinitPassword from './Accueil/ReinitPassword';

import './App.css';
import './gutenberg-style.css';
import './gutenberg-theme.css';
import FormulaireGlobal from './GlobalComponents/FormulaireGlobal';
import ModifPassword from './PageProfil/ModifPassword';


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Router basename={process.env.PUBLIC_URL}>
          <ScrollToTop />                
          <Header />
          <Switch>
            <Route path="/" exact component={Accueil} />
            <Route path="/moncompte/login.xhtml" exact component={Accueil} />
            <Route path="/actualites" exact component={ArchiveActus} />
            <Route path="/actualite/:id" render={(props) => <PageStandard postId={props.match.params.id} postType="post"/>} />
            <Route path="/page/:id" render={(props) => <PageStandard postId={props.match.params.id} postType="page"/>} />
            <Route path="/faq" exact component={ArchiveFaq} />
            <Route path="/login/:statut" exact render={(props) => <LoginAffilieEmployeur statut={props.match.params.statut} />}  />
            <Route path="/inscription/:statut" exact render={(props) => <InscriptionAffilieEmployeur statut={props.match.params.statut}/>} />
            <Route path="/reinitialisation-password" exact component={ReinitPassword} />
            
            <PrivateRoute path="/documents" exact component={ArchiveDocuments} />
            <PrivateRoute path="/logout" exact component={Logout} />
            <PrivateRoute path="/mes-cotisations" exact component={PageMesCotisations} />
            <PrivateRoute path="/page-profil" exact component={PageProfil} />
            <PrivateRoute path="/tableau-de-bord" exact component={TableauBordPersonnalise} />
           {/* <PrivateRoute path="/preparer-ma-retraite" exact component={PreparerMaRetraite} />*/}
            <PrivateRoute path="/demander-ma-retraite" exact component={DemanderMaRetraite} />
            <PrivateRoute path="/mes-droits-acquis" exact component={MesDroitsAcquisRetraite} />
            <PrivateRoute path="/ma-retraite" exact component={MaRetraite} />
            <PrivateRoute path="/formulaire-cotisations-paiement" exact component={FormCotisationsAPayer} />
            <PrivateRoute path="/formulaire-global" exact component={FormulaireGlobal} />
            <PrivateRoute path="/modification-password" exact component={ModifPassword} />

            {/* <Route path="/logout" exact component={Logout} />
            <Route path="/mes-cotisations" exact component={PageMesCotisations} />
            <Route path="/page-profil" exact component={PageProfil} />
            <Route path="/tableau-de-bord" exact component={TableauBordPersonnalise} />
            <Route path="/preparer-ma-retraite" exact component={PreparerMaRetraite} />
            <Route path="/demander-ma-retraite" exact component={DemanderMaRetraite} />
            <Route path="/mes-droits-acquis" exact component={MesDroitsAcquisRetraite} />
            <Route path="/ma-retraite" exact component={MaRetraite} />
            <Route path="/formulaire-cotisations-paiement" exact component={FormCotisationsAPayer} />
            <Route path="/formulaire-global" exact component={FormulaireGlobal} /> */}

            <Route path="/" component={()=> <div  className="presentation">Erreur 404</div>} />
          </Switch>
          <Footer />
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;

function BankAccount({ register, handleSubmit, watch, formState: { errors }, countryList, rib }) {
  return (
    <>
    <div className="form-group mb-5">
      <label htmlFor="iban" className="w-100">Code IBAN *</label>
      <input
        type="text"
        id="iban"
        defaultValue={rib && rib.iban  }
        name="iban"
        ref={register({required: true})}
        className="col-lg-6 col-md-8"
        placeholder="ex : FRxx xxxx xxxx..."
        />
    </div>
    <div className="form-group mb-5">
      <label htmlFor="swift" className="w-100">Code SWIFT *</label>
      <input
        type="text"
        id="swift"
        name="swift"
        ref={register({required: true})}
        className="col-lg-6 col-md-8"
        placeholder="ex: SOGEFRPPAAA"
        />
    </div>

    <div className="form-group row">
      <div className="col-md-6">

      <label htmlFor="country" className="w-100">Pays *</label>
      <select
        name="country"
        ref={register({required: true})}
        className="form-country w-100"
        >
        
        <option value="">Pays</option>
        {countryList && countryList.map((item, i) => {       
          return (
            <option value={item} key={i} >
              {item}
            </option>
          );
        })}
      </select>
      </div>
      <div className="col-md-6">

      <label htmlFor="domiciliation" className="w-100">Domiciliation Bancaire *</label>
      <input
        type="text"
        id="domiciliation"
        name="domiciliation"
        ref={register({required: true})}
        className="w-100"
        />
      </div>
    </div>


    <div className="form-group row">
      <div className="col-md-6">
      <label htmlFor="intitule" className="w-100 d-block">Intitulé du document</label>
        <input id="intitule" name="intitule" type="text" placeholder="ex: RIB" ref={register} />
        <label htmlFor="attachment" className="w-100 d-block">Pièce jointe</label>
        <input
          type="file"
          id="attachment"
          name="attachment"
          ref={register}
          />
          <label className="w-100 d-block">Format accepté : PDF, DOC(X), JP(E)G, PNG, ZIP <br/>Taille maximale : 10MB</label>
      </div>

    </div>

  
    </>
  );
}

export default BankAccount;
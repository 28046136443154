import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import parse from 'html-react-parser';
import Slider from "react-slick";
import SituationDemarches from "./components/SituationDemarches";
import RetraitePaiement from "./components/RetraitePaiement";
import TableInfosHistorique from "./components/TableInfosHistorique";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import useWpData from "../Hook/useWpData";
import useCavomData from "../Hook/useCavomData";
import { data } from 'jquery';
// import parse from 'html-react-parser';

const postId = 49;


function TableauBordPersonnalise(props) { 

  const { fetchWpPages, fetchWpPosts } = useWpData();
  const { fetchCavomData } = useCavomData();

  const [postData, setPostData] = useState('');

 // const [mes_alertes, setMesAlertes] = useState('');
  const [mes_demarches, setMesDemarches] = useState('');
  const [ma_situation, setMaSituation] = useState('');
  const [historique, setHistorique] = useState('');
  // const [en_cours, setEnCours] = useState('');
  // const [termine, setTermine] = useState('');
  // const [annule, setAnnule] = useState('');
  const [data_mon_identite, setMonId] = useState(''); // COTISANT, PRESTATAIRE, COTIS_PRESTA (-> Prestataire = retraité)
  const [actus_wp, setActuWp] = useState([]);
  // const [pension, setPension] = useState('');

  let retraite_profil = false; // passe a true si personne retraité

  if (data_mon_identite && (data_mon_identite.status == 'PRESTATAIRE') || (data_mon_identite && (data_mon_identite.status == 'COTIS_PRESTA'))){
    retraite_profil = true;
  }

  // let today = new Date();
  // let actualYear = today.getFullYear();
  // let actualQuarter = Math.floor((today.getMonth() + 3) / 3);
  
  let userNameConnexion = data_mon_identite.memberNumber != '' ? data_mon_identite.memberNumber : '';
 
  let presentation = '';

  if (postData){
    presentation = postData.ACF.presentation_tableau_bord;
  } 

  useEffect(async () => {
    setPostData(await fetchWpPages(postId));
    setActuWp(await fetchWpPosts('?page=1&per_page=5'));
    setMesDemarches(await fetchCavomData('api/dashboard/navigation'));
    setMaSituation(await fetchCavomData('api/dashboard/situation'));
    setMonId(await fetchCavomData('api/member/extended'));
    setHistorique(await fetchCavomData('api/demand'));

    // if (retraite_profil){
    //   setPension(await fetchCavomData(`api/member/pension?year=${actualYear}&quarter=${actualQuarter}`));
    // }

  }, [])

//  useEffect(async () => {
//    if (data_mon_identite && data_mon_identite.memberNumber)
//    setMesAlertes(await fetchCavomData('api/alerts/member/' + data_mon_identite.memberNumber));
//  }, [userNameConnexion])


  var settingsSlick = {
    dots: true,
    infinite: false,
    speed: 500,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  var settingsAlert = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };

      return(
        <>
          <section className="page-tdb">
            <div className="bandeau-header">
              <div className="container" style={{ backgroundImage: "url(images/background-presentation-accueil.jpg)" }}>
                <div className="row">
                  <h1>Tableau de bord</h1>
                </div>
              </div>
            </div>
            <div className="container page-tdb-infos cavom-center-content">
              <div className="row">
                <h2 className="col-12">Bienvenue {data_mon_identite.firstname} {data_mon_identite.name} </h2>
                {presentation &&
                  <div className="presentation col-12" dangerouslySetInnerHTML={ {__html: presentation} } />
                }

                {data_mon_identite && data_mon_identite.status &&
                data_mon_identite.status == 'PRESTATAIRE'
                ? <RetraitePaiement MaSituation={ma_situation} />
                : data_mon_identite.status != undefined && <SituationDemarches MaSituation={ma_situation} MesDemarches={mes_demarches} RetraiteProfil={retraite_profil} />
                }
                
                
                <div className="mon-historique w-100">
                  <h2>Mes demandes</h2>
                  <div className="attente-api">{}</div>
                  <div className="historique-table-wrapper">
                    {historique && historique !== '' && 
                      <TableInfosHistorique historique={historique} />
                    }
                  </div>
                </div>
                {actus_wp &&
                <div className="tdb-actus col-12">
                  <header className="tdb-actus-header">
                    <h2>Les actualités de la CAVOM</h2>
                    <Link to={`/actualites`} className="cavom-button gray-button">Voir tout</Link>
                  </header>
                
                  <Slider {...settingsSlick}>
                  {
                  actus_wp.map((item,i) => 
                    {   
                      const dateActu = new Date(item.date);
                      const formatDateActu = dateActu.toLocaleDateString("en-GB", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      });

                      return (
                      <article key={i} className="row d-flex global-relative-link">
                        <div className="col-md-4 thumbnail-wrapper">
                          {item.featured_image &&
                            <img src={item.featured_image} />
                          }
                        </div>
                        <div className="col-md-8 thumbnail-inner">
                        <Link to={`/actualite/${item.id}`}><h3 dangerouslySetInnerHTML={ {__html: item.title.rendered} } /></Link>
                          <div className="actu-date">{formatDateActu}</div>
                          <div className="excerpt" dangerouslySetInnerHTML={ {__html: item.excerpt.rendered} } />
                        </div>
                      </article>
                      )
                    }
                  )}
                  </Slider>
                </div>
                }
              </div>
            </div>
          </section>
        </>
      )
  }


export default TableauBordPersonnalise;
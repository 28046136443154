import React, { useState, useEffect } from "react";
import parse from 'html-react-parser';

import useWpData from "../Hook/useWpData";
import AccordionGutenberg from '../Assets/AccordionGutenberg';
// import Pagination from "../GlobalComponents/Pagination";

function ArchiveFaq(props) {
  const idFaq = 146;
  const [postData, setPostData] = useState('');
  const { fetchWpPages} = useWpData();
  const { fetchWpBase } = useWpData();
  const [search, setSearch] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [faqsRubriques, setFaqsRubriques] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectRubrique, setSelectRubrique] = useState('');
  // const [faqsCategories, setFaqsCategories] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [postsPerPage, setPostsPerPage] = useState(10);

  useEffect(async () => {
    setLoading(true);
    setPostData(await fetchWpPages(idFaq));
    setFaqs(await fetchWpBase('faq'));
    setFaqsRubriques(await fetchWpBase('rubriques-faq'));
    setLoading(false);
  }, []);


  const updateSearch = (event) => {
    setSearch(event.target.value)
  }

  const updateRubrique = (event) => {
    setSelectRubrique(event.target.value)
  }

  useEffect(() => {
    AccordionGutenberg();
  });

  let tempTabFaq = [];
  if (faqs.length && faqsRubriques.length){    
    faqsRubriques.map( (itemFR)=>{
        if (itemFR.slug === selectRubrique || selectRubrique == ''){
        let singleTabFaq = [];
        faqs.map((itemF)=>{
          if (itemF["rubriques-faq"].includes(itemFR.id)){
            singleTabFaq.push(itemF);
          }
        })
        tempTabFaq.push([itemFR.name, singleTabFaq])
      }
      } )
    }

    
          
          // Get current faqs
          // const indexOfLastPost = currentPage * postsPerPage;
          // const indexOfFirstPost = indexOfLastPost - postsPerPage;
          // const currentPosts = faqs.slice(indexOfFirstPost, indexOfLastPost);
          
          // Change page
          // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <section className="cavom-faq">
      <div className="bandeau-header">
        <div className="container" style={{ backgroundImage: "url(images/background-presentation-accueil.jpg)" }}>
          <div className="row">
            <h1>Foire aux questions</h1>
          </div>
        </div>
      </div>
      <div className="container cavom-center-content faq-results">
        {loading
        ? <h3>Chargement...</h3>
        : <div className="faq-results">
           <div className="archive-search-form">
        <input type="search" value={search} onChange={updateSearch} placeholder="Mots-clés" />
        {faqsRubriques &&
        <>
        <div className="select-wrapper">
          <select name="rubriques" id="rubrique-select" onChange={updateRubrique}>
          <option value="">Toutes les rubriques</option>
          {
            faqsRubriques.map((item, i)=>{
              return(
                <option value={item.slug} key={i}>{item.name}</option>
              )
            })
          }
          </select>
          </div>
        </>
        }
        </div>
            {tempTabFaq &&
            tempTabFaq.map( (itemTF,i) => {
              let indexRub = 0;
              {if (itemTF[1].length) {                
                  return (
                    <div className="faq-rubrique-wrapper" key={i}>
                      <h2>{itemTF[0]}</h2>
                      {
                        itemTF[1].map((item,i) => {
                          indexRub++
                          if ((item.title.rendered.toLowerCase().indexOf(search.toString().toLowerCase()) !== -1) ||(item.content.rendered.toLowerCase().indexOf(search.toString().toLowerCase()) !== -1)){
                            return(                     
                              <article key={i} className="faq-result">
                              <div className="wp-block-pb-accordion-item c-accordion__item js-accordion-item" data-initially-open="false" data-click-to-close="true" data-auto-close="false" data-scroll="false" data-scroll-offset="0">
                              <h3 id={`at-${indexRub}${item.id}`} className="c-accordion__title js-accordion-controller" role="button" tabIndex="0" aria-controls={`ac-${i}${item.id}`} aria-expanded="false" dangerouslySetInnerHTML={{ __html: item.title.rendered}} />
                              <div id={`ac-${indexRub}${item.id}`} className="c-accordion__content" hidden="hidden"><p dangerouslySetInnerHTML={{ __html: item.content.rendered}} />
                              </div>
                              </div>
                              </article> 
                            )

                          }
                        })
                      }
                  </div>             
                  )
                }   
              }

            } )
          }
          </div>
          
        }
        {/* <Pagination
          postsPerPage={postsPerPage}
          totalPosts={faqs.length}
          paginate={paginate}
        /> */}
      </div>
    </section>
  );
}

export default ArchiveFaq;

// Composant Mes Options de Cotisation
import React from 'react';
import { Link } from "react-router-dom";

function MesOptionsCotisations ({expliOption, memberOptions}) {


  return (
      <div className="options-coti gray-box gray-circles" style={{ backgroundImage: "url(images/background-gray-circles.jpg)" }}>
        <h2 className="toggle-title"><a data-toggle="collapse" href="#collapseOptions" role="button" aria-expanded="true" aria-controls="collapseExample">Mes Options de cotisations</a></h2>
        
        <div id="collapseOptions" className="container contentCollapse collapse show">
          <div className="row">
            <div className="col-md-6">
{//            <div className="expli-option" dangerouslySetInnerHTML={ {__html: expliOption} } />
}
            <h3>Mes options</h3>
            <div className="white-bg">
              {memberOptions && memberOptions.contributionOptionClass 
              ?
              <ul className="mes-options-coti">
                <li>Classe {memberOptions.contributionOptionClass}</li>
                {/* <li>Cotisation RID : <strong>B654987</strong></li>
                <li>Cotisation ID : <strong>X24587</strong></li>
              <li>Réversion à 100%</li> */}
              </ul>
              : 'N/A'
            }
            </div>
            <div className="options-coti-gerer">
              <Link to="/formulaire-global?formulaire=CONTRIBUTION_OPTION" className="cavom-button">Gérer mes options</Link>
            </div>
            </div>
            <div className="col-md-5 offset-md-1">
             <br/>
              {/*<Link to="#" className="cavom-button purple-button calc-icon">Simuler mes cotisations</Link>*/}
            </div>
          </div>
        </div>
      </div>
  )    
}

export default MesOptionsCotisations;
import React, {useEffect, useState} from 'react';
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import useCavomData from "../Hook/useCavomData";

function FormcotisationsAPayer(props){
  const location = useLocation()
  const frenchFormatter = new Intl.NumberFormat("fr-FR");

  const [connexionError, setConnexionError] = useState();
  const [lienDePaiement, setLienDePaiement] = useState();

  const { sendCavomData } = useCavomData();
  
  // manipulatino des data de paiement
  const data = location.state && location.state.data

  const arrayCoti = data.listIdCoti
  var listTabCoti=[]
  var listIdCoti = []
  var totalPayement = 0;
  arrayCoti.forEach( element => {
    listTabCoti.push(JSON.parse(element[1]))
    listIdCoti = listIdCoti.concat(JSON.parse(element[1]).associatedDeadlines)
  });

  const handlePayement = async () => {
    const bodyPaiement = {
      deadlineIds: listIdCoti
    }

    try {
      const response = await sendCavomData("api/payment", bodyPaiement);
      
      if (response.com6responsestatus == 200){
        setLienDePaiement(response.url)
      }            
    } catch (e) {
      window.scroll({
        top: 200,
        left: 0,
        behavior: 'smooth',
      });      
      setConnexionError(e.message);
    }     
  }

  useEffect(()=>{
    console.log('useeffect handlepayement')
    handlePayement();
  }, [data]);



  return(
    <section className="paiement-cotisations">
        <div className="bandeau-header">
        <div className="container" style={{ backgroundImage: "url(images/background-presentation-accueil.jpg)" }}>
          <div className="row">
            <h1>Paiement des cotistations</h1>
          </div>
        </div>
      </div>
      <div className="container paiement-wrapper">
      {connexionError && <p className="alert alert-danger" role="alert" dangerouslySetInnerHTML={{ __html: connexionError }} />}
        <div className="row">
          <div className="col-12 gray-table-wrapper">
            <h3>Votre sélection à payer :</h3>
            <table>
              <thead>
                <tr>
                <th>Date</th>
                <th>Montant</th>
                </tr>
              </thead>
            {listTabCoti.length &&
            <tbody>
            {listTabCoti.map( (item,i) => {
              totalPayement += item.amount
              return(                
              <tr key={i}>
                <td><span className="date-wrapper">{item.deadlineDate}</span></td>
                <td>{frenchFormatter.format(item.amount)} €</td>
              </tr>
              )
            })
          }
            </tbody>
            }
            </table>              
            <p>Montant total à payer pour votre sélection : <span className="font-weight-bold">{totalPayement} €</span></p>
          </div>
          <div className="col-12">
            <Link to="/mes-cotisations" className="cavom-button">Modifier ma sélection</Link>
            {/* <button onClick={handlePayement} className="cavom-button">Générer le lien de paiement</button> */}
            {!lienDePaiement &&
              <div className="spinner-border ml-5" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            }
            {lienDePaiement &&
            <a href={lienDePaiement} className="cavom-button">Payer {totalPayement} €</a>
            }
          </div>
        </div>
      </div>
    </section>    
  )

}

export default FormcotisationsAPayer;
import React from 'react';

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage, show }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  var lastPage = pageNumbers.length;


  return (
    show && show == true && <nav className='nav-pagination'>
        <div className="previous-page">
          {(currentPage > 1)
           ? <a onClick={() => paginate(currentPage - 1)}  className='prev-link'>Précédent</a>
           : <p className="prev-link inactive">Précédent</p> 
           }
        </div>
        <ul className='pagination'>
          {pageNumbers.map(number => (
            <li key={number} className='page-item'>
              <a onClick={() => paginate(number)}   className={`page-link ${number == currentPage ? 'current' : ''}`}>
                {number}
              </a>
            </li>
          ))}
        </ul>
        <div className="next-page">
        {(currentPage < lastPage)
           ? <a onClick={() => paginate(currentPage + 1)}   className='next-link'>Suivant</a>
           : <p className="next-link inactive">Suivant</p> 
           }
        </div>
    </nav>
  );
};

export default Pagination;
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import useCavomData from "../Hook/useCavomData";

import PhoneNumber from "./FormulaireGlobal/PhoneNumber";
import GeneralRequest from "./FormulaireGlobal/GeneralRequest";
import AdressChange from "./FormulaireGlobal/AdressChange";
import BankAccount from "./FormulaireGlobal/BankAccount";
import ContributionOption from "./FormulaireGlobal/ContributionOption";
import EmailChange from "./FormulaireGlobal/EmailChange";
import EstimatedRevenue from "./FormulaireGlobal/EstimatedRevenue";
import RetirementRequest from "./FormulaireGlobal/RetirementRequest";
import BeneficiaryChange from "./FormulaireGlobal/BeneficiaryChange";


function FormulaireGlobal(props) {
   console.log(props.location.state);
  const { sendCavomData, fetchCavomData } = useCavomData();


  const [formName, setFormName] = useState("");
  const [libellesSelect, setLibellesSelect] = useState("");
  const [postErrors, setPostErrors] = useState();
  const [postSuccess, setPostSuccess] = useState();
  const [selectGeneralRequest, setSelectGeneralRequest] = useState();
  const [data_mon_identite, setMonId] = useState("");
  const [listeBeneficiaires, setListeBeneficiaires] = useState("");
  const [countryList, setCountryList] = useState("");
  const [streetTypes, setStreetTypes] = useState("");


  const queryParams = new URLSearchParams(window.location.search);
  //const formSelect = queryParams.get('formulaire');
  //const addressType = queryParams.get('type');
  const formSelect = props.location.state.formulaire;
  const addressType = props.location.state && props.location.state.type;

  const forms = {
    GENERAL_REQUEST: <GeneralRequest selectGeneralRequest={selectGeneralRequest} />,
    PHONE_NUMBER_CHANGE: <PhoneNumber addressType={addressType} phone={props.location.state.phone}/>,
    ADDRESS_CHANGE:<AdressChange addressType={addressType} address={props.location.state.address}/>,
    EMAIL_CHANGE: <EmailChange email={props.location.state.email}/>,
    ESTIMATED_REVENUE: <EstimatedRevenue />,
    CONTRIBUTION_OPTION: <ContributionOption data={props.location.state.data}/>,
    RETIREMENT_REQUEST: <RetirementRequest />,
    BANK_ACCOUNT: <BankAccount rib={props.location.state.rib}/>,
    BENEFICIARY_CHANGE: <BeneficiaryChange listeBeneficiaires={listeBeneficiaires}/>
  };
  
  const listeLibelles = [
    "GENERAL_REQUEST",
    "ADDRESS_CHANGE",
    "EMAIL_CHANGE",
    "ESTIMATED_REVENUE",
    "PHONE_NUMBER_CHANGE",
    "CONTRIBUTION_OPTION",
    "RETIREMENT_REQUEST",
    "BANK_ACCOUNT",
    "BENEFICIARY_CHANGE"
    ];

      const mapLibelles = new Map([
        ["GENERAL_REQUEST", "Demande générale"],
        ["ADDRESS_CHANGE", "Changement de coordonnées"],
        ["EMAIL_CHANGE", "Changement d'adresse mél"],
        ["ESTIMATED_REVENUE", "Déclaration de revenu"],
        ["PHONE_NUMBER_CHANGE", "Modification de numéro de téléphone"],
        ["CONTRIBUTION_OPTION", "Changement de classe de cotisation RID"],
        ["RETIREMENT_REQUEST", "Demande de retraite"],
        ["BANK_ACCOUNT", "Modification de coordonnées bancaires"],
        ["BENEFICIARY_CHANGE" , "Ajout d'un nouveau bénéficiaire" ]
        ]);

      const addressLabels = new Map([
        ["PHONE_NUMBER_CHANGE_PROFESSIONAL", "professionnel"],
        ["ADDRESS_CHANGE_PROFESSIONAL", "professionnelle"],
        ["PHONE_NUMBER_CHANGE_PERSONAL", "personnel"],
        ["ADDRESS_CHANGE_PERSONAL", "personnelle"],
        ["EMAIL_CHANGE_PERSONAL", "de contacte"],
        ["BANK_ACCOUNT_PROFESSIONAL", ""]

        ]);





  const {
    register,
    handleSubmit,
    watch,
    formState,
    formState: { errors },
  } = useForm();

  const { isSubmitting } = formState;
  // const [desableSubmit, setDesableSubmit] = useState(false);

  // Conversion pdf to base64
  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  // A la soumission du formulaire global
  const onSubmit = async (data) => {
  

    setPostErrors('')
    setPostSuccess('')

    var Data;
    // switch case de libelles
    // Modif Téléphone
    if (formSelect === "PHONE_NUMBER_CHANGE") {
      let privateAddress = data_mon_identite.privateAddress ? true : false
      // let privateAddress = false
      let proAddress = data_mon_identite.professionalAddress ? true : false

      if ( (data.Type == "PERSONAL" && !privateAddress) || (data.Type == "PROFESSIONAL" && !proAddress) ){
        setPostErrors('Vous devez renseigner une adresse avant d\'ajouter un numéro de téléphone');
        return
      }

      // Création du motif attendu par l'API CAVOM
      Data = {
        requestType: "PHONE_NUMBER_CHANGE",
        type: addressType,
        phoneNumber: data.telephone_perso,
      };
    }else if (formSelect === "ADDRESS_CHANGE") {
      // Modification Adresse
      // Création du motif attendu par l'API CAVOM
      var attachment_array = []
      if (data.attachment.length > 0) {

        const pdfToBase64 = await toBase64(data.attachment[0])

        var attachmentObject = {
          subject: data.intitule,
          fileName: data.attachment[0].name,
          documentBody: pdfToBase64.replace(/^data:(.*,)?/, '')
        }     
        
        attachment_array.push(attachmentObject)
      }
      Data = {
        requestType: "ADDRESS_CHANGE",
        type: addressType,
        numVoie: data.num_voie,
        typeVoie: data.type_voie,
        nomVoie: data.nom_voie,
        complementAddress: data.cplt_add1,
        complementAddress2: data.cplt_add2,
        lieuDitouBoitePostale: data.lieu_dit,
        cedex: data.cedex,
        codePostal: data.code_postal,
        commune: data.commune,
        attachments: attachment_array,
        pays: data.country
      };     
    }else if(formSelect === "BANK_ACCOUNT") {
      // Ajout d'un compte bancaire
      // Création du motif attendu par l'API CAVOM
      var attachment_array = []
      if (data.attachment.length > 0) {

        const pdfToBase64 = await toBase64(data.attachment[0])

        var attachmentObject = {
          subject: data.intitule,
          fileName: data.attachment[0].name,
          documentBody: pdfToBase64.replace(/^data:(.*,)?/, '')
        }     
        
        attachment_array.push(attachmentObject)
      }
      Data = {
        requestType: "BANK_ACCOUNT",
        iban: data.iban,
        swift: data.swift,
        country: data.country,
        residency: data.domiciliation,
        attachments: attachment_array,
      };

    }else if(formSelect === "CONTRIBUTION_OPTION"){
      // Changement de classe de cotisation
      Data = {
        requestType: "CONTRIBUTION_OPTION",
        year: data.year,
        targetOptionCategory: data.target_option,
        currentOptionCategory: data.current_option,
      };
      console.log(Data);
    }else if(formSelect === "EMAIL_CHANGE"){
      // Changement d'email
      Data = {
        requestType: "EMAIL_CHANGE",
        email: data.email,      };
    }else if(formSelect === "ESTIMATED_REVENUE"){
      // Déclaration de revenu estimé
      Data = {
        requestType: "ESTIMATED_REVENUE",
        type : data.type,
        amount: data.montant,      
        year: data.year,  
        deficit: data.deficit 
      };
    }else if(formSelect === "BENEFICIARY_CHANGE"){
    const dateAnniv = new Date(data.dateNaissance);
    const formatDateAnniv = dateAnniv.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
      // Ajout d'un nouveau bénéficiaire
      Data = {
        requestType: "BENEFICIARY_CHANGE",
        beneficiary :  {
          title : data.title,
          lastName: data.lastName,
          firstName: data.firstName,
          birthDate: formatDateAnniv,
          birthPlaceCode: data.departementNaissance,
          type: data.typeBeneficiaire,
          nir : data.nir
          } 
      };
    }else if(formSelect === "RETIREMENT_REQUEST"){
      // Demande de retraite / Complément de dossier
      var attachment_array = []  

      if (data.attachment.length > 0) {

        const pdfToBase64 = await toBase64(data.attachment[0])

        var attachmentObject = {
          subject: data.intitule,
          fileName: data.attachment[0].name,
          documentBody: pdfToBase64.replace(/^data:(.*,)?/, '')
        }     
        
        attachment_array.push(attachmentObject)
      }
      
      Data = {
        requestType: "RETIREMENT_REQUEST",
        description: data.description,
        attachments: attachment_array,
      };
    }else if(formSelect === "GENERAL_REQUEST"){
      // Demande Générale
      var attachment_array = []
      if (data.attachment.length > 0) {          
        const pdfToBase64 = await toBase64(data.attachment[0])

        var attachmentObject = {
          subject: data.intitule,
          fileName: data.attachment[0].name,
          documentBody: pdfToBase64.replace(/^data:(.*,)?/, '')
        }    
        
        attachment_array.push(attachmentObject)
      }
      Data = {
        requestType: "GENERAL_REQUEST",
        description: data.description,
        motif: data.subLibellesGeneralRequest,
        section: data.libellesGeneralRequest,
        attachments: attachment_array,
      };
    }

    //Envoie des data sur l'API CAVOM
    try {
      const data = await sendCavomData("api/demand", Data);
      if (data.com6responsestatus == 202){
        setPostSuccess('Votre demande a bien été envoyée');
      }
      // if (data.com6responsestatus == 403){
      //   setPostErrors('Une erreur s\'est produite');
      // }
    } catch (e) {
        console.error(e);
        setPostErrors(e.message);
    }

    // setDesableSubmit(false)

  };


  useEffect(async () => {
    // Récupération de la liste des libellés de General Request

    setSelectGeneralRequest(await fetchCavomData('api/section'))
    setMonId(await fetchCavomData("api/member/summary"))
    setListeBeneficiaires(await fetchCavomData("api/member/beneficiaries"))
    setCountryList (await fetchCavomData("api/repository/country"))
    setStreetTypes (await fetchCavomData("api/repository/streetTypes"))
  }, []);


  useEffect(() => {
    if (listeLibelles.includes(formSelect)){
      setLibellesSelect(formSelect)      
    }
  }, [formSelect]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [postErrors, postSuccess])

  return (
    <>
    <div className="bandeau-header">
      <div
        className="container"
        style={{
          backgroundImage:
            "url(images/background-presentation-accueil.jpg)",
        }}
      >
        <div className="row">
          <h1>{mapLibelles.get(formSelect)  +" "+ (addressType ? addressLabels.get(formSelect + "_" + addressType) : "" )} </h1>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">

    <section className="col-12">
        
        {postErrors && (
          <p className="alert alert-danger col-12" role="alert">
                { postErrors }
              </p>
            )}
        {postSuccess && (
          <p className="alert alert-success col-12" role="alert">
                { postSuccess }
              </p>
            )}

        <form onSubmit={handleSubmit(onSubmit)} className="formulaire-global col-12">

          {libellesSelect &&
            React.cloneElement(forms[libellesSelect], {
              register,
              handleSubmit,
              watch,
              formState: { errors },
              countryList,
              streetTypes
          })}
          {libellesSelect &&
          <div className="row submit-wrapper">
            <input
              type="submit" 
              className="cavom-button dark-blue-button p-3 ml-0 mr-0" 
              disabled={isSubmitting} 
              />
          </div>
          }

        </form>
    </section>
</div>
</div>
    </>
  );
}

export default FormulaireGlobal;

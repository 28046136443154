function RetirementRequest({ register, handleSubmit, watch, formState: { errors } }) {
  return (
    <>
    <div className="form-group mb-5">
      <label htmlFor="description" className="w-100 d-block">Description de votre demande *</label>
      <textarea
        id="description"
        name="description"
        ref={register({required: true})}
        style={{
          height: '200px',
          width: '500px',
          maxWidth: '100%'
        }}
        />
    </div>
    
    <div className="form-group row">
      <div className="col-md-6">
        <label htmlFor="intitule" className="w-100 d-block">Intitulé du document</label>
        <input id="intitule" name="intitule" type="text" placeholder="ex: Attestation sur l'honneur" ref={register} />

        <label htmlFor="attachment" className="w-100 d-block">Pièce jointe</label>
        <input
          type="file"
          id="attachment"
          name="attachment"
          ref={register}
          />
              <label className="w-100 d-block">Format accepté : PDF, DOC(X), JP(E)G, PNG, ZIP <br/>Taille maximale : 10MB</label>
      </div>

    </div>

  
    </>
  );
}

export default RetirementRequest;
import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";


function MesArrieresDePaiement ( {dataCoti, contentieux} ) {

  const frenchFormatter = new Intl.NumberFormat("fr-FR", {minimumFractionDigits: 2});
  const { push } = useHistory();
  const { register, handleSubmit, watch, formState: {  errors,  isSubmitting, isDirty, isValid   } } = useForm();
  let paymentButton = false;

  const [arrieresPaimentError, setArrieresPaimentError] = useState(false);

  // Au submit du Form - récupération des data de paiement et redirect sur page de paiement
  const onSubmit = data => {
    var listIdCoti = [];
    Object.entries(data).forEach(element => {
      if (element[1] != false){
        listIdCoti.push(element)
      }      
    });
    if (listIdCoti.length){
      push({
        pathname: '/formulaire-cotisations-paiement',
        state: {
          data: {
            listIdCoti: listIdCoti
          }
        }
      })
    }else{
      setArrieresPaimentError(true);
    }
  }

    return (
        <div id="echeancier-de-paiement" className="mes-prelevements gray-box">
          <h2 className="toggle-title"><a data-toggle="collapse" href="#collapseArrieres" role="button" aria-expanded="true" aria-controls="collapseExample">Mes arriérés de paiement</a></h2>
          
          <div id="collapseArrieres" className="container contentCollapse show">
            <div className="row">
              {contentieux.litigation &&
              <div className=" col-12 profil-contentieux">
                Votre dossier est en contentieux
              </div>
              }
              <div className="col-12">
                <div className="row">
                  <div className="gray-table-wrapper col-12">
                    <form id="formToPayOlder" onSubmit={handleSubmit(onSubmit)}>

                      <table>
                        <thead>
                          <tr>
                          <th className="checkbox"></th>
                            <th>Date</th>
                            <th>Reste à payer</th>
                            <th>Etat de l'échéance</th>
                          </tr>
                        </thead>
                        { dataCoti && 
                          <tbody>
                            
                            {dataCoti.map( (item,i) =>{ 
                            // création de la Table - ajout input si paiement possible  
                            var jsonValues = {
                              deadlineDate: item.deadlineDate,
                              amount: item.amount,
                              associatedDeadlines: item.associatedDeadlines
                            }
                            var paymentToPay = item.state == 'EN_COURS' ? true : false
                            if ( paymentToPay ) paymentButton = true                           
                            var inputToPay = paymentToPay ? <><input type="checkbox" id={'inputToPay-arr' + i} name={item.id} value={JSON.stringify(jsonValues)} ref={register} /><label htmlFor={'inputToPay-arr' + i} className="mb-0"></label></> : '';
                            return(
                              <tr key={i}>
                                <td>{inputToPay}</td>
                                <td><span className="date-wrapper">{item.deadlineDate}</span></td>
                                <td className="amount-td"><span className="amount-left">{frenchFormatter.format(item.amount)}€</span></td>
                                <td>{ item.state == 'EN_COURS' ? 'En cours' : 'Soldée' }</td>
                              </tr>                          
                            )
                          })}
                        </tbody>
                        }
                      </table>

                      <div className="row">

                      {paymentButton &&
                        <div className="col-md-6">
                        <input type="submit" name="submitToPay" value="Payer" className="cavom-button" disabled={!isDirty || !isValid} />
                        </div>
                      }
                      {arrieresPaimentError &&
                      <p className="alert alert-danger" role="alert">Vous devez sélectionner au moins un montant à payer</p>
                      }
                      </div>
                    </form>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
    )


}

export default MesArrieresDePaiement;
import React from "react";

function TableInfosHistorique(historique){

  // const dateAnniv = new Date(data.dateNaissance);


  const optionsDate = {
    year: "numeric",
    month: "long",
    day: "2-digit",
  }

const renderSwitch = (param) => {
  var  itemStatusDecode = '';
  var  cssStatus = '';
  switch(param){
    case 'IN_PROGRESS':
      itemStatusDecode = "En cours";
      cssStatus = 'encours'
      break;
      case 'TO_BE_PROCESSED':
        itemStatusDecode = "A traiter";
        cssStatus = 'encours'
        break;
        case 'PENDING':
          itemStatusDecode = "En attente";
          cssStatus = 'encours'
          break;
        case 'PROCESSED':
          itemStatusDecode = "Traité";
          cssStatus = 'termine'
          break;
        case 'CLASSIFIED':
          itemStatusDecode = "Classé";
          cssStatus = 'annule'
      break;
  }
  return (
  <td className={`historique-status ${cssStatus}`}>{itemStatusDecode}</td>
  )
    
  
}
  return(
    <div>
      <table className="historique-table">
        <tbody>
          {historique && historique.historique &&
         historique.historique.map( (item,i) => {
           var dateCreation = new Date(item.creationDate)
            return(

              <tr key={i}>
            <td className="historique-id">#{item.id}</td>
            <td>{item.motif.replaceAll("_", " ").replaceAll("DAD", "D'AD").replaceAll("EMAIL", "MÉL")
            .replaceAll("MODIFICATIONS ADRESSES", "CHANGEMENT D'ADRESSE").replaceAll("CREATION", "CRÉATION")
            .replaceAll("TELEPHONE", "TÉLÉPHONE") }</td>
            <td className="historique-date">{dateCreation.toLocaleDateString("fr-FR", optionsDate)}</td>
            {renderSwitch(item.status)}
          </tr>
            )
          })
          }
        </tbody>
      </table>

    </div>
  )
}

export default TableInfosHistorique;
import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";


function MonEcheancierPaiement({cotiEnCours, memberSchedule, contentieux, attestationPaiment }) {

  const frenchFormatter = new Intl.NumberFormat("fr-FR", {minimumFractionDigits: 2});
  const { push } = useHistory();
  let paymentButton = false;

  const { register, handleSubmit, watch, formState: { errors,  isSubmitting, isDirty, isValid  } } = useForm({
    mode: "onChange"
  });

  const [echeancePaimentError, setEcheancePaimentError] = useState(false);

  // Au submit du Form - récupération des data de paiement et redirect sur page de paiement
  const onSubmit = data => {
    var listIdCoti = [];
    Object.entries(data).forEach(element => {
      if (element[1] != false){
        listIdCoti.push(element)
      }
    });
    if (listIdCoti.length){

      push({
        pathname: '/formulaire-cotisations-paiement',
        state: {
          data: {
            listIdCoti: listIdCoti
          }
        }
      })
    }else{
      setEcheancePaimentError(true);
    }
  }


  async function openPdfBlob(e) {
    const pdfData = attestationPaiment;

    const file = new Blob([pdfData], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  // vérifitcation de l'existence de cotisations
  const dataCoti = memberSchedule.length > 0 ?  memberSchedule : false;


          return (
          <div >
             <div id="echeancier-de-paiement" className="echeancier mes-prelevements gray-box">
                <h2 className="toggle-title"><a data-toggle="collapse" href="#collapseEcheancier" role="button" aria-expanded="true" aria-controls="collapseExample">Mon échéancier de paiement</a></h2>
                <div id="collapseEcheancier" className="container contentCollapse collapse show">
                  <div className="col1">
                	<div  className="alert alert-danger" >
													<h3>Le paiement par carte bancaire est momentanément indisponible</h3>
												</div>
                  <div className="col-12">
                      <div className="row">
												<div className="col-lg-4 col-md-6 montant_coti">
													<h3>Montant de la cotisation année en cours</h3>
													<p>{frenchFormatter.format(cotiEnCours.amount)} €</p>
												</div>
												<div className="col-lg-4 col-md-6 montant_paye">
													<h3>Montant payé année en cours</h3>
													<p>{frenchFormatter.format(cotiEnCours.settledAmount)} €</p>
												</div>
												<div className="col-lg-4">
													<div className=" reste_payer striped-bg" style={{ backgroundImage: "url(images/striped-bg-tile.jpg)" }}>
                            <div className="white-shadow-bg">
                              <h3>Reste à payer</h3>
                              <p>{frenchFormatter.format(cotiEnCours.amount - cotiEnCours.settledAmount)} €</p>
                            </div>
                          </div>
												</div>
                      </div>
                    </div>

										{contentieux.litigation &&
										<div className=" col-12 profil-contentieux">
											Votre dossier est en contentieux
										</div>
										}
                    <div className="col-12">
											<h3>Mes paiements</h3>
											<div className="row">
												<div className="gray-table-wrapper col-12">
                          <form id="formToPay" onSubmit={handleSubmit(onSubmit)}>
                            <table>
                              <thead>
                                <tr>
                                  <th className="checkbox"></th>
                                  <th>Date</th>
                                  <th>Reste à payer</th>
                                  <th>Etat de l'échéance</th>
                                </tr>
                              </thead>
                              { dataCoti &&
                                <tbody>

                                 {dataCoti.map( (item,i) =>{
                                  // création de la Table - ajout input si paiement possible
                                  var jsonValues = {
                                    deadlineDate: item.deadlineDate,
                                    amount: item.amount - item.amountPayed,
                                    associatedDeadlines: item.associatedDeadlines
                                  }
                                  var paymentToPay = item.state == 'EN_COURS' ? true : false
                                  if ( paymentToPay ) paymentButton = true;
                                  var inputToPay = paymentToPay ? <><input type="checkbox" id={'inputToPay-' + i} name={item.id} value={JSON.stringify(jsonValues)} ref={register} /><label htmlFor={'inputToPay-' + i} className="mb-0"></label></> : '';
                                  return(
                                    <tr key={i}>
                                      <td>{inputToPay}</td>
                                      <td><span className="date-wrapper">{item.deadlineDate}</span></td>
                                      <td className="amount-td"><span className="amount-left">{frenchFormatter.format(item.amount - item.amountPayed)}€</span></td>
                                      <td>{ item.state == 'EN_COURS' ? 'En cours' : 'Soldée' }</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                              }
                            </table>
                            <div className="row">

                              <div className="col-md-6">
                              {paymentButton &&
                              <input type="submit" name="submitToPay" value="Payer" className="cavom-button" disabled={ true || !isDirty || !isValid} />
                              }
                              {echeancePaimentError &&
                              <p className="alert alert-danger" role="alert">Vous devez sélectionner au moins un montant à payer</p>
                              }
                              </div>
                            </div>
                          </form>
												</div>
												<div className="col-12">

                        </div>
											</div>
                    </div>

                  </div>
                </div>
              </div>
          </div>
          )

}

export default MonEcheancierPaiement;
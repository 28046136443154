import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useAuth } from "../Hook/useAuth";

/**
 * Route pour déconnecter l'utilisateur. Redirige vers l'accueil
 */
export default function Logout() {
  const { logout } = useAuth();

  useEffect(() => logout(), []);

  return <Redirect to="/" />;
}

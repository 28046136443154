import parse from "html-react-parser";
import React, { useState}  from "react";

function AdressChange({register, handleSubmit, watch, formState: { errors }, countryList, streetTypes, address }) {

  const [streetType, setStreetType] = useState(address && address.streetType);
  const [country, setCountry] = useState(address && address.country);

  return (
    <>
      <div className="form-group row">
        <div className="col-md-2">
          <label htmlFor="num_voie" className="w-100">N° *</label>
          <input
            defaultValue={address && address.streetNumber}
            type="text"
            id="num_voie"
            name="num_voie"
            ref={register({required: true})}
            placeholder="ex : 24"
            />
          </div>
        <div className="col-md-4">


          <label htmlFor="type_voie" className="w-100">Type *</label>
          <select
            value= {streetType}
            type="text"
            id="type_voie"
            name="type_voie"
            ref={register({required: true})}
            onChange={e => setStreetType(e.target.value)}
            className="form-country" style={{width: "300px", padding: "4px"}}>


            {streetTypes && streetTypes.map((item) => {
            return (
                <option value={item.code} key={item.code}>
                    {item.code}
                </option>
                );
            })}
          </select>
        </div>

        <div className="col-md-6">

          <label htmlFor="nom_voie" className="w-100">Nom *</label>
          <input
            type="text"
            id="nom_voie"
            name="nom_voie"
            defaultValue={address && address.streetName}
            ref={register({required: true})}
            placeholder="ex : du Canal"
            />

        </div>

      </div>


        <div className="form-group row">
          <div className="col-md-6">
            <label htmlFor="cplt_add1" className="w-100">Complément d'adresse 1</label>
            <input
              type="text"
              id="cplt_add1"
              name="cplt_add1"
              defaultValue={address && address.addressComplement1}
              ref={register}
              placeholder="ex : Complément 1"
              />

          </div>

        </div>

        <div className="form-group row">
          <div className="col-md-6">
            <label htmlFor="cplt_add2" className="w-100">Complément d'adresse 2</label>
            <input
              type="text"
              id="cplt_add2"
              name="cplt_add2"
              defaultValue={address && address.addressComplement2}
              ref={register}
              placeholder="ex : Complément 2"
              />

          </div>

        </div>

        <div className="form-group row">
          <div className="col-md-6">
            <label htmlFor="lieu_dit" className="w-100">Lieu dit ou Boîte postale</label>
            <input
              type="text"
              id="lieu_dit"
              name="lieu_dit"
              defaultValue={address && address.placeSaidOrPostalBox}
              ref={register}
              placeholder="ex : BP 31"
              />

          </div>

        </div>

        <div className="form-group row">
          <div className="col-md-6">
            <label htmlFor="cedex" className="w-100">Cedex</label>
            <input
              type="text"
              id="cedex"
              name="cedex"
               defaultValue={address && address.cedex}
              ref={register}
              placeholder="ex : Cedex 9"
              />

          </div>

        </div>

        <div className="form-group row">
          <div className="col-md-4">
            <label htmlFor="code_postal" className="w-100">Code postal *</label>
            <input
              type="text"
              id="code_postal"
              name="code_postal"
              defaultValue={address && address.postalCode}
              ref={register({
                required: true,
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Chiffres uniquement autorisés',
                }
              })}
              placeholder="ex : 31000"
              />
              {errors.code_postal && <span className="form-error" role="alert">{parse(errors.code_postal.message)}</span>}

          </div>
          <div className="col-md-8">
            <label htmlFor="commune" className="w-100">Commune *</label>
            <input
              type="text"
              id="commune"
              name="commune"
              defaultValue={address && address.cityName}
              ref={register({required: true})}
              placeholder="ex : Toulouse"
              />
          </div>
          <div className="col-12">

          <select
            value={country}
            name="country"
            ref={register({required: true})}
            className="form-country"
            onChange={e => setCountry(e.target.value)}
            >
           
            <option value="">Pays</option>
            {countryList && countryList.map((item, i) => {       
              return (
                <option value={item} key={i} >
                  {item}
                </option>
              );
            })}
          </select>


          </div>

        </div>

        <div className="form-group row">
          <div className="col-md-6">
          <label htmlFor="intitule" className="w-100 d-block">Intitulé du document</label>
          <input id="intitule" name="intitule" type="text" placeholder="ex: Avis d'imposition" ref={register} />
            <label htmlFor="attachment" className="w-100 d-block">Pièce jointe</label>

            <input
              type="file"
              id="attachment"
              name="attachment"
              ref={register}
              />
              <label className="w-100 d-block">Format accepté : PDF, DOC(X), JP(E)G, PNG, ZIP <br/>Taille maximale : 10MB</label>

          </div>

        </div>
      
    </>
  );
}

export default AdressChange;
import React from 'react';
import { Link } from "react-router-dom";

function CompteExistant ({postData, emailForNewValidation}) {

  let texteCompteExistant = '';
  if (postData && postData.ACF){
    texteCompteExistant = postData.ACF.texte_compte_existant
  }
  return(
    <div className="container create-notif compte-existant">
      <div className="row">
        <div className="col-12">
          <h2>Ce compte existe déjà</h2>
          <p className="subtitle">Veuillez vous connecter</p>

          {emailForNewValidation &&
            <p className="txt-expli-notif">Un lien d'activation vous a été adressé par mél à l'adresse : {emailForNewValidation}</p>
          }
          <div className="exist'-account'-link">
            <Link to="/" className="login-link cavom-button">Me connecter</Link>
            <Link to="/reinitialisation-password" className="reinit-link cavom-button">Réinitialiser mon mot de passe</Link>
          </div>
        </div>
      </div>

    </div>
  )

}

export default CompteExistant;
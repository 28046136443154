// Composant Mes Cotisations
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import TableCotiPrevYear from "./TableCotiPrevYear";


const frenchFormatter = new Intl.NumberFormat("fr-FR", {minimumFractionDigits: 2});
const incomeFrenchFormatter = new Intl.NumberFormat("fr-FR", {minimumFractionDigits: 0});


function MesCotisations ({Presentation, expliCoti, cotiPrevYear, cotiEnCours, paymentMethod, openPdf, openPdfYearBlob, waitingSpinner, dejaPaye}) {

    var cotiAnEncours = '';
    var soldeCoti = '';
    var montantPaye = '';
    var laDate = new Date()
    var tabMois = new Array("Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre");
    var moisEnCours = tabMois[laDate.getMonth()]
    if (cotiEnCours){
      cotiAnEncours = cotiEnCours.amount ? cotiEnCours.amount : 0;
      montantPaye = cotiEnCours.settledAmount ? cotiEnCours.settledAmount : 0;
      soldeCoti = cotiEnCours.stillDueAmount;
    }
    let nextLevy = (dejaPaye && (dejaPaye.nextDeadline != undefined) && (typeof dejaPaye.nextDeadline.amount === 'number')) ? dejaPaye.nextDeadline.amount : 'N/A'
    let nextDate = (dejaPaye && (dejaPaye.nextDeadline != undefined) && dejaPaye.nextDeadline.deadlineDate) ? dejaPaye.nextDeadline.deadlineDate : ''
    let nextDatePiece = nextDate.split("/")
    let nextDateMonth = tabMois[parseInt(nextDatePiece[1] - 1)]

      return (
          <div className="mes-cotisations cavom-center-content">
            <h2>Mes cotisations</h2>
            <div className="presentation-coti center-content-intro" dangerouslySetInnerHTML={ {__html: Presentation} } />
              <div className="row">
                <div className="col-md-4 presentation-coti-buttons">
                  {/* <DeclarationPaiement user-array={ userArray }/> */}
                  <div className="expli-coti" dangerouslySetInnerHTML={ {__html: expliCoti} } />
                  <div className="liens-coti">
                   <a  href="https://kube.cavom.lan/wordpress-rec/wp-content/uploads/2022/06/notice_explicative.pdf" className="lien-guide  cavom-button dark-blue-button file-icon" target='_blank'>Comprendre le calcul &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>

                  {cotiEnCours.year &&
                    <Link to={`/mes-cotisations`} className="lien-detail d-block cavom-button zoom-icon" docuid={cotiEnCours.year} onClick={openPdfYearBlob}>Consulter le détail des calculs</Link>
                   }
                    <a href="https://www.cavom.net/wp-content/uploads/2022/05/CAVOM_Guide-2022_EXE_V2_PRINT.pdf" className="lien-guide d-block cavom-button dark-blue-button file-icon" target='_blank'>Consulter le guide pratique</a>

                  </div>
                </div>
                <div className="col-md-8 cotisations-tables">
                  {cotiEnCours &&
                    <div className="coti-en-cours blue-tab" style={{ backgroundImage: "url(images/background-blue-table.jpg)" }}>
                          <h3>Mes cotisations {cotiEnCours.year} (<u>Provisionnelles</u>)</h3>
                          <table className="table-cot-current">
                          <thead>
                            <tr>
                              <th>Montant appelé</th>
                              <th>Montant réglé</th>
                              <th>Solde restant dû</th>
                            {/*  (paymentMethod == 'PRELEVEMENT') &&
                              <th>Prélèvement {nextDateMonth}</th>
                              */}
                            </tr>
                          </thead>
                          <tbody>
                              <tr>
                              <td>{frenchFormatter.format(cotiAnEncours)} €</td>
                              <td>{frenchFormatter.format(montantPaye)} €</td>
                              <td>{frenchFormatter.format(soldeCoti)} €</td>
                            {/*  (paymentMethod == 'PRELEVEMENT') &&
                              <td>{nextLevy != 'N/A' ? frenchFormatter.format(nextLevy) : 'N/A'} €</td>
                             */ }
                            </tr>
                        </tbody>
                        </table>
                        {(paymentMethod == 'PRELEVEMENT') &&
                          <div className="coti-links">
                          <HashLink to="#mes-prelevements" className="voir-coti">Voir mes prélèvements</HashLink>
                        </div>
                        }
                       {/*
                       <a className="dl-appel-coti dl-pdf" docuid={cotiEnCours.year} onClick={openPdfYearBlob}>Télécharger l'appel de cotisations</a>
                      */}
                        <ul className="coti-modes">
                            <li>Assiette de calcul : <span>
                              {
                              cotiEnCours.incomeAmount != (undefined && null)
                              ? incomeFrenchFormatter.format(cotiEnCours.incomeAmount) + ' € ('+ cotiEnCours.incomeLabel + ')'
                              : 'revenu inconnu' }
                              </span>&nbsp;&nbsp;
                              <span>
                              <Link  to={{ pathname: "/formulaire-global", state: { formulaire: "ESTIMATED_REVENUE"} }}> Déclarer mes revenus</Link></span>
                              </li>
                            <li>Mode de paiement : <span>{paymentMethod == "CHEQUE" ? "TIP" : paymentMethod}</span></li>
                        </ul>
                     </div>
                  }
                  {(cotiPrevYear !== '') && 
                  <>
                  <div className="gray-table-wrapper">
                    <h3>Mes cotisations des années précédentes</h3>
                    <TableCotiPrevYear data={cotiPrevYear} openPdfYearBlob={openPdfYearBlob} waitingSpinner={waitingSpinner} />

                  </div>
                  </>
                  }
                </div>
              </div>
          </div>
      )
    
}


// Bloc infos Déclaration - Paiement

// function DeclarationPaiement (props) {
//     return (
//       <>
//         <div className="declaration">
//           {props['user-array'].declaration_faite
//           ?<><p>Déclaration de revenus effectuée le { props['user-array'].declaration_faite }</p>
//             <Link to="#" className="lien-url cavom-button">Modifier ma déclaration</Link> </>
//           :<><p>Déclarez vos revenus avant le { props['user-array'].declaration_afaire }</p>
//             <Link to="#" className="lien-url">Effectuer ma déclaration</Link> </>           
//           }
//           {            
//           (paymentMethod != 'PRELEVEMENT') && 
//           <>
//           {props['user-array'].paiement_coti_fait
//             ? <p>Paiement de cotisation effectué le {props['user-array'].paiement_coti_fait}</p>
//             : <p>Payez vos cotisations avant le {props['user-array'].paiement_coti_afaire}</p>    }
//           <Link to="#" className="mr-2">Payer ma cotisation</Link>  
//           <Link to="#">Adhérer au prélèvement</Link>  
//           </>
//           }

//         </div>
//       </>
//     )
// }


export default MesCotisations;

import React from "react";
import { Link } from "react-router-dom";

const frenchFormatter = new Intl.NumberFormat("fr-FR");

function RetraitePaiement({MaSituation}){

  return(
      <div className="col-12 retraite-paiement">
        <div className="row">
          <h2 className="col-12">Ma Retraite</h2>
          <div className="col-lg-6 d-flex">
            <div className="rp-wrapper rp-next rp-block">
              <h3>Votre prochain paiement d'un montant de</h3>
              <p className="rp-montant">{(MaSituation && MaSituation.pensionDto && MaSituation.pensionDto.next && MaSituation.pensionDto.next.amount) ? frenchFormatter.format(MaSituation.pensionDto.next.amount) : 'N/A'} € net</p>
              {MaSituation && MaSituation.pensionDto && MaSituation.pensionDto.next && MaSituation.pensionDto.next.date &&
                <p className="rp-date">Versement le {MaSituation.pensionDto.next.date}</p>
              }
              <Link className="rp-link" to="/ma-retraite?quarter=PREVIOUS">Consulter le détail</Link>
            </div>
          </div>
          <div className="col-lg-6 d-flex">
            <div className="rp-wrapper rp-prev rp-block">
              <h3>Votre montant du trimestre précédent</h3>
              <p className="rp-montant">{(MaSituation && MaSituation.pensionDto && MaSituation.pensionDto.last && MaSituation.pensionDto.last.amount) ? frenchFormatter.format(MaSituation.pensionDto.last.amount) : 'N/A'} € net</p>
              {MaSituation && MaSituation.pensionDto && MaSituation.pensionDto.last && MaSituation.pensionDto.last.date &&
                <p className="rp-date">Versement le {MaSituation.pensionDto.last.date}</p>
              }
              <Link className="rp-link" to="/ma-retraite?quarter=CURRENT">Consulter le détail</Link>
            </div>
          </div>
        </div>
        
      </div>
  )

}
export default RetraitePaiement;
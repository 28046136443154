

function ErrPage() {

    
  return (
    <div className="header container-fluid">
       <div className="alert alert-danger" role="alert">
      <h2 className="alert-heading">Problème d'accès</h2>
      <p>
      Nous rencontrons des problèmes techniques actuellement, l'accès aux espaces sécurisés est temporairement indisponible.
      </p>
    </div>
      </div>)
}
export default ErrPage;
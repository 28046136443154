import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import { useForm } from "react-hook-form";
import useCavomData from "../../Hook/useCavomData";
import AccordionGutenberg from '../../Assets/AccordionGutenberg';
import parse from "html-react-parser";

function FormReinitPassword ({aideReinitPassword, setComposantSelect, postData}) {
  
  const { sendCavomData } = useCavomData();
  const [connexionError, setConnexionError] = useState();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    errors
  } = useForm();
  const onSubmit = async ({ dateNaissance, email}) => {
    setConnexionError(null);
    
    const dateAnniv = new Date(dateNaissance);
    
    const formatDateAnniv = dateAnniv.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    
    const reinitPassword = {
      requestType: "PASSWORD_CHANGE_WITH_EMAIL_VALIDATION",
      email: email,
      birthDate: formatDateAnniv
    }
    
    try {
      const response = await sendCavomData("api/demand/account/password", reinitPassword);
      
      if (response.com6responsestatus == 201){
        setComposantSelect('SendPasswordScreen')
      }else if(response.com6responsestatus == 404){
        setConnexionError('Utilisateur introuvable !')
      }
            
    } catch (e) {
      window.scroll({
        top: 200,
        left: 0,
        behavior: 'smooth',
      });
      
      setConnexionError(e.message);
    }
  };

  useEffect(() => {
    AccordionGutenberg();
  });
  
  return (
    <section
    className="reinit-pwd"
    >
      <div className="bandeau-header">
        <div className="container">
          <div className="row">
            <h1>Mot de passe</h1>
          </div>
        </div>
      </div>

      <div className="container cavom-center-content">
        <div className="row">
          <div className="col-md-5">
            <h2>Réinitialisez votre mot de passe</h2>

            <form onSubmit={handleSubmit(onSubmit)}>

              {connexionError && <p className="alert alert-danger" role="alert" dangerouslySetInnerHTML={{ __html: connexionError }} />}

              <br/><br/>
               <label>
                      <span>Adresse mél :*</span>
               </label>
              <input
                type="text"
                name="email"
                ref={register({ 
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Adresse mél invalide"
                  }
                })}
                placeholder="Vérification d'adresse mél"
                />
                {errors.email && <span className="form-error w-100" role="alert">{parse(errors.email.message)}</span>}
                 <br/> <br/>
              <label>
                <span  >Date de naissance :*</span>
              </label>
              <input
                type="date"
                name="dateNaissance"
                ref={register({ required: true })}
                placeholder="Date de naissance"
                />

                <input
                  type="submit"
                  disabled={isSubmitting}
                  className="cavom-button"
                  value="Valider"
                  />
            </form>
          </div>

          <div className="aide-connexion offset-md-2 col-md-5">
            <h2 className="help-icon">Aide</h2>
            {postData && postData.ACF && postData.ACF.faq_reinit_password &&
            <div className="faq-wrapper">
            {
            postData.ACF.faq_reinit_password.map((item, i) => {

              return(                     
                <article key={i} className="faq-result">
                <div className="wp-block-pb-accordion-item c-accordion__item js-accordion-item" data-initially-open="false" data-click-to-close="true" data-auto-close="false" data-scroll="false" data-scroll-offset="0">
                <h3 id={`at-${item.ID}`} className="c-accordion__title js-accordion-controller" role="button" tabIndex="0" aria-controls={`ac-${item.ID}`} aria-expanded="false" dangerouslySetInnerHTML={{ __html: item.post_title}} />
                <div id={`ac-${item.ID}`} className="c-accordion__content" hidden="hidden"><p dangerouslySetInnerHTML={{ __html: item.post_content}} />
                </div>
                </div>
                </article> 
              )              
            })
            }   
            </div>
            }
            <div
              className="aide-affilie"
              dangerouslySetInnerHTML={{ __html: aideReinitPassword }}
              />
              <Link to="/page/253">Contactez-nous</Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FormReinitPassword;
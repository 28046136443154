import React, {useState, useRef} from 'react';
import useCavomData from "../../Hook/useCavomData";
import { useForm } from "react-hook-form";

function CompteExistantNonActive ({postData, emailForNewValidation, setComposantSelect}) {

  const { sendCavomData } = useCavomData();
  const { register, handleSubmit, formState: { isSubmitting }, errors, watch } = useForm();
  const [postErrors, setPostErrors] = useState();
  const [formChangeMail, setFormChangeMail] = useState(false);

  const email = useRef({});
  email.current = watch("email", "");

  let texteCompteNonActive = '';
  if (postData && postData.ACF){
    texteCompteNonActive = postData.ACF.texte_compte_non_active
  }

  const handleNewActivation = async () => {
      const newValidationWithEmail = {
        requestType: "EMAIL_VALIDATION",
        email: emailForNewValidation
      }

      try {
        const response = await sendCavomData("api/demand/account/validation", newValidationWithEmail);
  
        if (response.com6responsestatus == 201) {
          console.log(response.message);
          setComposantSelect('SendScreen')
        }
      } catch (e) {
        if (e.message) {
            console.log(e)
            setPostErrors(e.message)
        } else {
            console.log(e)
            setPostErrors("Une erreur inattendue est survenue");
        }
      }

  }

  const handleFormChangeMail = () => {
    setFormChangeMail(true);
  }

  const handleChangeMail = async (data) => {

    const dateAnniv = new Date(data.dateNaissance);

    const formatDateAnniv = dateAnniv.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const changeMailValidation = {
      lastName:  data.name,
      firstName: data.prenom,
      email: emailForNewValidation,
      username: data.idCavom,
      nir: data.nir,
      birthDate: formatDateAnniv,
      newEmail: data.email
    }

    console.log(changeMailValidation)

    try {
      const response = await sendCavomData("api/account/updatemail", changeMailValidation)

      if (response.com6responsestatus == 200){
        setComposantSelect('SendScreen')
      }

      if (response.message) {
        setPostErrors(response.message);
      }
    } catch (e) {
      window.scroll({
        top: 200,
        left: 0,
        behavior: 'smooth',
      });
      if (e.message) {
        console.log(e)
        setPostErrors(e.message);
      } else {
        console.log(e)
        setPostErrors("Une erreur inattendue est survenue");
      }
    }

  }

  return(
    <div className="container create-notif compte-existant">
      {postErrors && (
        <p className="alert alert-danger" role="alert">
          { postErrors }
        </p>
      )}
      <div className="row">
        <div className="col-12">
        <h2>Ce compte existe déjà mais il n'est pas activé :</h2>
        <p className="subtitle">Un lien d'activation vous a été adressé par mél</p>
        {/*texteCompteNonActive &
        <p className="txt-expli-notif" dangerouslySetInnerHTML={ {__html: texteCompteNonActive} } />
        */}

        <button onClick={handleNewActivation} className="cavom-button">Me renvoyer le mél d'activation</button>
        {emailForNewValidation &&
        <p className="mail-contact">Voici le mél sur lequel nous allons envoyé la demande : {emailForNewValidation}</p>
        }

        <p className="new-mail">Votre adresse mél ne fonctionne pas ? <button onClick={handleFormChangeMail} className="cavom-button">Changer mon adresse mél</button></p>

        {formChangeMail &&
        <div className="form-modif-mail">
          <form onSubmit={handleSubmit(handleChangeMail)} >
              <label>
                <span className="sr-only">Identifiant CAVOM :</span>
              </label>

              <input
                type="text"
                name="idCavom"
                placeholder="Identifiant CAVOM OMxxxxx*"
                ref={register({ required: true })}
              />

              
              <label>
                <span className="sr-only">Sécurité sociale :</span>
                <input
                  type="text"
                  name="nir"
                  placeholder="N° sécurité sociale*"
                  ref={register({ required: true })}
                />
              </label>

              <label>
                <span className="sr-only">Nom :</span>
              </label>

              <input
                type="text"
                name="name"
                placeholder="Nom*"
                ref={register({ required: true })}
              />

              <label>
                <span className="sr-only">Prénom :</span>
              </label>

              <input
                type="text"
                name="prenom"
                placeholder="Prénom*"
                ref={register({ required: true })}
              />

              <label>Date de naissance :*</label>

              <input
                type="date"
                name="dateNaissance"
                placeholder="Date de naissance"
                ref={register({ required: true })}
              />

              <label>
                <span className="sr-only">Adresse mél :</span>
              </label>

              <input
                type="email"
                name="email"
                placeholder="Nouvel email*"
                ref={register({ required: true })}
              />

              <label>
                <span className="sr-only">Confirmer votre adresse mél :</span>
              </label>

              <input
                type="email"
                name="email_repeat"
                placeholder="Confirmer votre adresse mél*"
                ref={register({
                  validate: (value) =>
                    value === email.current || "L'adresse mél ne correspond pas.",
                })}
              />

              {errors.email_repeat && (
                <p className="form-error" role="alert">{errors.email_repeat.message}</p>
              )}

              <input
                type="submit"
                className="cavom-button"
                disabled={isSubmitting}
                value="Demande d'inscription"
              />

          </form>
        </div>
      }
        
      </div>
      </div>
    </div>
  )
}

export default CompteExistantNonActive;
// fichier écran connexion affilié
import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import AccordionGutenberg from '../Assets/AccordionGutenberg';
import { useForm } from "react-hook-form";

import useWpData from "../Hook/useWpData";
import { useAuth } from "../Hook/useAuth";

import ErrPage from './PortailInaccessible/ErrPage';

function LoginAffilieEmployeur({ statut }) {
  const accueil_id = "13";
  const [userMail, setUserMail] = useState('');
  const { login, user } = useAuth();
  const { fetchWpPages } = useWpData();
  const [postData, setPostData] = useState("");
  const [activationAgain, setActivationAgain] = useState(false);
  const [connexionError, setConnexionError] = useState();
  const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
      // When the handler is invoked
      // inverse the boolean state of passwordShown
      setPasswordShown(!passwordShown);
    };
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const[recaptchaOk, recaptchaCallback] = useState(false);
  const onSubmit = async ({ username, password }) => {
    setConnexionError(null);

    try {
      await login(username, password);
    } catch (e) {
      if(e.code == 1002){   
        console.log(e)
        setUserMail(e.data)
        setActivationAgain(true)
      }
      window.scroll({
        top: 200,
        left: 0,
        behavior: 'smooth',
      });
      
      setConnexionError(e.message);
    }
  };

  let aideConnexionAffilie = "";
  let primoConnexionAffilie = "";

  const showError = false;

  if (postData) {
    aideConnexionAffilie = postData.ACF.aide_connexion_affilie;
    primoConnexionAffilie = postData.ACF.premiere_connexion_affilie;
  }

  useEffect(async () => {
    setPostData(await fetchWpPages(accueil_id));
  }, []);

  useEffect(() => {
    AccordionGutenberg();
  });

  // Si l'utilisateur est connecté on redirige
  if (user) {
    return <Redirect to="/tableau-de-bord" />;
  }

  return (
    <section
      className={
        statut == "employeur"
          ? "page-connexion-employeur page-employeur"
          : "page-connexion-affilie page-affilie"
      }
    >
      <div className="bandeau-header">
        <div
          className="container"
          style={
            statut == "employeur"
              ? {
                  backgroundImage:
                    "url(images/bandeau-connexion-employeur-2.jpg)",
                }
              : {
                  backgroundImage: "url(images/bandeau-connexion-affilie.jpg)",
                }
          }
        >
          <div className="row">
            <h1>Connexion</h1>
          </div>
        </div>
      </div>
      {  showError && (<ErrPage/>) }
      <div id="login-portail" className="container cavom-center-content">
        <div className="row">
          <div className="connexion-portail col-md-5">
            <h2>Connectez-vous</h2>

            {activationAgain &&
            <>
            <p>Vous désirez recevoir un nouveau mél d'activation ?</p>
              <Link to={{pathname: "/inscription/affilie?composantSelect=AlreadyExistNonActiveScreen&userMail=" + userMail}} className="cavom-button">Recevoir nouveau mél</Link>
            </>
            }


            <form onSubmit={handleSubmit(onSubmit)}>
              {connexionError && <p className="alert alert-danger" role="alert" dangerouslySetInnerHTML={{ __html: connexionError }} />}

              {statut == "employeur" ? (
                <>
                  <label>
                    <span className="sr-only">Numéro de Siret :</span>
                  </label>
                  <input
                    type="text"
                    name="username"
                    ref={register({ required: true })}
                    placeholder="Numéro de Siret"
                  />
                </>
              ) : (
                <>
                  <label>
                    <span className="sr-only">Identifiant :</span>
                  </label>
                  <input
                    type="text"
                    name="username"
                    autoComplete="new-password"
                    ref={register({ required: true })}
                    placeholder="Identifiant CAVOM OMxxxxx*"
                  />
                </>
              )}

              <label>
                <span className="sr-only">Mot de passe :</span>
              </label>
              <input
                type={passwordShown ? "text" : "password"}
                name="password"
                autoComplete="new-password"
                ref={register({ required: true })}
                placeholder="Mot de passe"
              />
            <input type="checkbox" id="showpassword" name="showpassword"  onClick={togglePassword} />
            <label for="showpassword">Afficher le mot de passe</label>


              <div className="forget-password">
                <Link to="/reinitialisation-password" className="gray-link">
                  Mot de passe oublié ?
                </Link>
              </div>


              {statut == "employeur" ? (
                <input
                  type="submit"
                  disabled={isSubmitting || !recaptchaOk}
                  className="cavom-button green-button"
                  value="Se connecter"
                />
              ) : (
                <input
                  type="submit"
                  disabled={isSubmitting || !recaptchaOk}
                  className="cavom-button"
                  value="Se connecter"
                />
              )}

            </form>
             {
              <div className="recaptcha-google">
                <p>Veuillez cocher la case ci-dessous pour continuer :</p>
                <ReCAPTCHA
                  sitekey="6LfzjiQhAAAAAMtysEZKx7mnqUOnlHSvffh6xhud"
                  render="explicit"
                  onChange={recaptchaCallback}

                />

              </div>
              }
          </div>

          <div className="aide-connexion offset-md-2 col-md-5">
            <h2 className="help-icon">Aide</h2>
            {postData && postData.ACF && postData.ACF.faq_connexion &&
            <div className="faq-wrapper">
            {
            postData.ACF.faq_connexion.map((item, i) => {

              return(                     
                <article key={i} className="faq-result">
                <div className="wp-block-pb-accordion-item c-accordion__item js-accordion-item" data-initially-open="false" data-click-to-close="true" data-auto-close="false" data-scroll="false" data-scroll-offset="0">
                <h3 id={`at-${item.ID}`} className="c-accordion__title js-accordion-controller" role="button" tabIndex="0" aria-controls={`ac-${item.ID}`} aria-expanded="false" dangerouslySetInnerHTML={{ __html: item.post_title}} />
                <div id={`ac-${item.ID}`} className="c-accordion__content" hidden="hidden"><p dangerouslySetInnerHTML={{ __html: item.post_content}} />
                </div>
                </div>
                </article> 
              )              
            })
            }   
            </div>

            }
            <div
              className="aide-affilie"
              dangerouslySetInnerHTML={{ __html: aideConnexionAffilie }}
            />
            <Link to="/page/253">Contactez-nous</Link>
          </div>

          <div className="first-connexion-zone col-12 bt-dotted">
            <h2>Première connexion</h2>

            <div
              className="txt-primoco"
              dangerouslySetInnerHTML={{ __html: primoConnexionAffilie }}
            />

            {statut == "employeur" ? (
              <Link
                to="/inscription/employeur"
                className="cavom-button green-button"
              >
                S'inscrire
              </Link>
            ) : (
              <Link to="/inscription/affilie" className="cavom-button">
                S'inscrire
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginAffilieEmployeur;

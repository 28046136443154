// fichier écran réinitialisation de mot de passe
import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";


import useWpData from "../Hook/useWpData";
import FormReinitPassword from "./ReinitPassword/FormulaireReinitPassword";
import FormModifPassword from "./ReinitPassword/FormModifPassword";


function ReinitPassword() {

  const queryParams = new URLSearchParams(window.location.search);
  const apitoken = queryParams.get('apitoken');
  const accueil_id = "13";
  const { fetchWpPages } = useWpData();  
  const [postData, setPostData] = useState("");  
  const [composantSelect, setComposantSelect] = useState('Formscreen');
  
  let aideReinitPassword = "";

  if (postData) {
    aideReinitPassword = postData.ACF.aide_reinit_password;
  }

  useEffect(async () => {
    setPostData(await fetchWpPages(accueil_id));
  }, []);


  return(
    <>
    {(apitoken != null) && (apitoken != '') && (composantSelect != 'SendNewPassword')
    ? <FormModifPassword apitoken={apitoken} setComposantSelect={setComposantSelect} />
    : <>
      {composantSelect == 'Formscreen' &&
      <FormReinitPassword setComposantSelect={setComposantSelect} aideReinitPassword={aideReinitPassword} postData={postData} />
      }
      {composantSelect == 'SendPasswordScreen' &&
      <>
      <div className="bandeau-header">
      <div className="container">
        <div className="row">
          <h1>Mot de passe</h1>
        </div>
      </div>
    </div>
      <div className="cavom-center-content">
      <div className="row">
        <div className="col-12">
          <h3>Votre demande a bien été envoyée.</h3>
          <p>Vous allez recevoir un mél de modification de votre mot de passe.</p>
          <Link to="/" className="link-accueil cavom-button"l>Retourner à l'accueil du site</Link>
        </div>
      </div>
      </div> 
      </>   
      }
      {composantSelect == 'SendNewPassword' &&
      <>
      <div className="bandeau-header">
        <div className="container">
          <div className="row">
            <h1>Mot de passe</h1>
          </div>
        </div>
      </div>
        <div className="container cavom-center-content">
          <div className="row">
            <div className="col-12">
              <h3>Votre mot de passe a bien été changé.</h3>
              <Link to="/" className="link-accueil cavom-button">Retourner à l'accueil du site</Link>
            </div>
          </div>
        </div>
        </>
      }
      </>
    }

    </>
  )

}

export default ReinitPassword;

import parse from "html-react-parser";

function EstimatedRevenue({ register, handleSubmit, watch, formState: { errors } }) {
  return (
    <>
    <div className="form-group row">
         <div className="col-md-2">

                <select
                        type="text"
                        id="type"
                        name="type"
                        className="form-country"
                        ref={register({
                          required: true,
                          validate: (value) =>
                                  value !== "" || "Veuillez saisir le type du revenu",
                        })}
                        >
                          <option value="">Type du revenu</option>
                          <option value="estimated">Estimé</option>
                          <option value="final">Définitif</option>

                        </select>
               </div>
    </div>
    <div className="form-group row">
      <div className="col-md-3">
        <label className="w-100 d-block mb-3">Est-ce un déficit ?</label>
        <input
          type="checkbox"
          id="deficit"
          name="deficit"
          ref={register}
          />
        <label htmlFor="deficit" className="w-100">Oui</label>
      </div>


      <div className="col-md-4">
        <label htmlFor="year" className="w-100">Année *</label>
        <input
          type="text"
          id="year"
          name="year"
          ref={register({
            required: true,
            pattern: {
              value: /^[0-9]+$/,
              message: 'Chiffres uniquement autorisés',
            }
          })}
          placeholder="ex : 2022"
          />
          {errors.year && <span className="form-error" role="alert">{parse(errors.year.message)}</span>}
      </div>
      <div className="col-md-5">
        <label htmlFor="montant" className="w-100">Montant du revenu *</label>
        <input
          type="text"
          id="montant"
          name="montant"
          ref={register({
            required: true,
            pattern: {
              value: /^[0-9]+$/,
              message: 'Chiffres uniquement autorisés',
            }
          })}
          placeholder="ex : 340000"
          />
          {errors.montant && <span className="form-error" role="alert">{parse(errors.montant.message)}</span>}
      </div>
    </div>


    </>
  );
}

export default EstimatedRevenue;
import { is } from "express/lib/request";
import React from "react";

function TableDroitsAcquis ({tabRecapCavomData, setIndexTabRecapCavomData, isFetching}) {
  const numberPages = tabRecapCavomData.totalPages
  const currentPage = tabRecapCavomData.currentPage

  const slideTable = (e) => {
    const slideToDisplay = e.target.getAttribute('data-slide')
    if (slideToDisplay >= 0 && slideToDisplay <= (numberPages - 1)){
      setIndexTabRecapCavomData(slideToDisplay)
    }
    
  }
  let slickDotsCreateClass = ''
  const frenchFormatter = new Intl.NumberFormat("fr-FR");

  return (
    <div className="table-droits-aquis-wrapper position-relative">
      {isFetching &&
      <div className="spinner-wrapper">
        <div className="spinner-border" role="status">
          <span className="sr-only">Chargement...</span>
        </div>
      </div>

      }
           
      <button type="button" data-slide={currentPage - 1} onClick={slideTable} className="slick-arrow slick-prev"> Previous</button>
          
    
      <table>
          <thead>
            <tr>
              <th className="year">Année</th>
              <th>Régimes</th>
              <th>Points</th>
            </tr>
          </thead>
        <tbody>
        {tabRecapCavomData.memberPoints.map((item,i) => {
          return (
              <tr key={i}>
              <td className="year">{item.year}</td>
              <td>
              { (item.planStatus == 'RB' && 'Retraite de base')
              || (item.planStatus == 'RC' && 'Retraite complémentaire')
              }
              </td>
              <td className="points">{frenchFormatter.format(item.totalPoint)} pts</td>                    
              </tr>
          )
        })}
        </tbody>
      </table>
      <button type="button" data-slide={currentPage + 1} onClick={slideTable} className="slick-arrow slick-next"> Next</button>
      <ul className="slick-dots">
        {[...Array(numberPages)].map((x, i) =>{
          slickDotsCreateClass = currentPage == (i) ? 'slick-active' : ''
          return(
            <li key={i} className={slickDotsCreateClass}><button data-slide={i} onClick={slideTable}>{i}</button></li>
          )
        }
        )}
        
      </ul>
    </div> 
  )
}

export default TableDroitsAcquis;
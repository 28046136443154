import { is } from "express/lib/request";
import React from "react";
import { format } from "date-fns";

function TableValeurPoint ({tabRecapCavomData, setIndexTabRecapCavomData, isFetching}) {

console.log(tabRecapCavomData.totalPages);
  const numberPages = tabRecapCavomData.totalPages
  const currentPage = tabRecapCavomData.currentPage

  const slideTable = (e) => {
    const slideToDisplay = e.target.getAttribute('data-slide')
    if (slideToDisplay >= 0 && slideToDisplay <= (numberPages - 1)){
      setIndexTabRecapCavomData(slideToDisplay)
    }

  }
  let slickDotsCreateClass = ''
  const frenchFormatter = new Intl.NumberFormat("fr-FR");

  return (
    <div className="table-droits-aquis-wrapper position-relative">
      {isFetching &&
      <div className="spinner-wrapper">
        <div className="spinner-border" role="status">
          <span className="sr-only">Chargement...</span>
        </div>
      </div>

      }

      <button type="button" data-slide={currentPage - 1} onClick={slideTable} className="slick-arrow slick-prev"> Previous</button>


      <table>
          <thead>
            <tr>
              <th className="year">Date de début</th>
              <th className="year">Date de fin</th>
              <th>Valeur du point</th>
            </tr>
          </thead>
        <tbody>
        {tabRecapCavomData.pointValues.map((item,i) => {
          return (
              <tr key={i}>
              <td className="year">{format(new Date(item.date), "dd/MM/yyyy")}</td>
              <td className="year">{item.endDate !== null ?  format(new Date(item.endDate) , "dd/MM/yyyy") : "En cours d'application"}</td>
              <td className="points">{frenchFormatter.format(item.numValue)}</td>
              </tr>
          )
        })}
        </tbody>
      </table>
      <button type="button" data-slide={currentPage + 1} onClick={slideTable} className="slick-arrow slick-next"> Next</button>
      <ul className="slick-dots">
        {[...Array(numberPages)].map((x, i) =>{
          slickDotsCreateClass = currentPage == (i) ? 'slick-active' : ''
          return(
            <li key={i} className={slickDotsCreateClass}><button data-slide={i} onClick={slideTable}>{i}</button></li>
          )
        }
        )}

      </ul>
    </div>
  )
}

export default TableValeurPoint;
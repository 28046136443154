import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import Slider from "react-slick";

import useWpData from "../Hook/useWpData";
import Sidebar from "./Sidebar";
import AccordionGutenberg from '../Assets/AccordionGutenberg';

function PageStandard({ postId, postType }) {
  const { fetchWpPages, fetchWpPosts } = useWpData();
  const [post, setPost] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    setLoading(true);

    if (postType == "post") {
      setPost(await fetchWpPosts(postId));
    } else if (postType == "page") {
      setPost(await fetchWpPages(postId));
    }

    setLoading(false);
  }, [postId]);

  useEffect(() => {
  
    AccordionGutenberg();

  });

  if (loading) {
    return <h3>Chargement...</h3>;
  }

  return (
    <section className="page-standard">
      <div className="bandeau-header">
        <div className="container" style={{ backgroundImage: "url(images/background-presentation-accueil.jpg)" }}>
          <div className="row">
          {Object.keys(post).length > 0 && (
            <>
              <h1>{post.title.rendered}</h1>
            </>
          )}
          {post.type == "post" && (
            <div className="actus-link">
              <Link to={`/actualites`} className="cavom-button white-button">Toutes les actualités</Link>
            </div>
          )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <article className="single-post cavom-center-content col-md-8">
            {Object.keys(post).length > 0 && (
              <>
                <div
                  className="page-standard-content"
                  dangerouslySetInnerHTML={{ __html: post.content.rendered }}
                />
              </>
            )}
          </article>

          <Sidebar post={post} />
        </div>
      </div>
    </section>
  );
}

export default PageStandard;

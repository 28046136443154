import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import useWpData from "../Hook/useWpData";
// import parse from 'html-react-parser';

const postId = 155; // ID Page Retraite WP

function PreparerMaRetraite(props) { 

  const { fetchWpPages } = useWpData();

  const [postData, setPostData] = useState('');

  let processRetraiteData = '';
  let blocMesDroits = '';
  let blocPreparerRetraite = '';
  let blocPreparerDossier = '';
  let blocDemanderRetraite = '';
  let blocRecapCavom = '';
  let blocRecapCarriere = '';

  if (postData && postData.ACF){
    if (postData.ACF.process_retraite)
    processRetraiteData = postData.ACF.process_retraite;
    if (postData.ACF.bloc_mes_droits)
    blocMesDroits = postData.ACF.bloc_mes_droits;
    if (postData.ACF.bloc_comment_preparer_ma_retraite)
    blocPreparerRetraite = postData.ACF.bloc_comment_preparer_ma_retraite;
  } 

  useEffect(async () => {
    setPostData(await fetchWpPages(postId));
  }, [])
  

  var settings = {
    customPaging: function(i) {
      return (
        <a dangerouslySetInnerHTML={ {__html: processRetraiteData[i].titre_bulle} }>
        </a>
      );
    },
    dots: true,
    infinite: false,
    speed: 500,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };

      return(
        <>
          <div className="bandeau-header">
            <div className="container" style={{ backgroundImage: "url(images/background-presentation-accueil.jpg)" }}>
              <div className="row">
                <h1>Préparer ma Retraite</h1>
              </div>
            </div>
          </div>
          <div className="container cavom-center-content">
            <div className="row">

          <section className="col-12 slider-preparer-retraite">
            <h2>Le process de la retraite</h2>
            <Slider {...settings}>
                {processRetraiteData &&
                processRetraiteData.map((item,i) =>
                <article key={i} className="row d-flex">
                    <div className="col-md-8">
                      <div className="excerpt" dangerouslySetInnerHTML={ {__html: item.contenu_slider} } />
                    </div>
                  </article>
                )
              }
            </Slider>
          </section>
          <section className="col-12">
          {blocMesDroits &&
            <>
            <h2>Mes droits</h2>
            <div className="presentation" dangerouslySetInnerHTML={ {__html: blocMesDroits} } />
            <Link to="/mes-droits-acquis" className="cavom-button">Mes droits acquis</Link>
            <a href="https://www.info-retraite.fr/portail-services/login" className="cavom-button" target='_blank'>Mon relevé de carrière</a>
//            <Link to="#" className="cavom-button">Simuler mes droits</Link>
            </>
          }
          </section>
          <section className="col-12">
          {blocPreparerRetraite &&
          <>
          <h2>Comment préparer ma retraite</h2>
          <div className="presentation" dangerouslySetInnerHTML={ {__html: blocPreparerRetraite} } />
          </>
          }
          </section>

          </div>
        </div> 
        </>
      )
  }


export default PreparerMaRetraite;
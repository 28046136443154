import { useState } from "react";
import parse from "html-react-parser";

function BeneficiaryChange({ register, handleSubmit, watch, formState: { errors }, listeBeneficiaires }) {
  const [typeBeneficiaire, setTypeBeneficiaire] = useState('')

  return (
    <>
    <div className="form-group d-flex flex-wrap col-md-8">
      <div className="row">
      <label htmlFor="typeBeneficiaire" className="sr-only">Type de bénéficiaire</label>
      <select
        onChange={(e) => 
          {
            setTypeBeneficiaire(e.target.value)
          }
          }
        name="typeBeneficiaire"
        ref={register({ required: true })}
        className="form-select mb-3"
        >
        <option value="">Type de bénéficiaire*</option>
        <option value="SPOUSE">Epouse / Epoux</option>
        <option value="CHILD">Enfant</option>
        <option value="OTHER">Autre</option>
        </select>
      </div>
    </div> 
    <div className="form-group col-md-8">
      <div className="row">
      {typeBeneficiaire != "CHILD" &&
      <select name="title" className="form-select mb-5" ref={register({ required: true })}>
        <option value="">Civilité*</option>
        <option value="MRS">Madame</option>
        <option value="MR">Monsieur</option>
      </select>
      }
      

      <label htmlFor="firsName">
        <span className="sr-only">Prénom :</span>
      </label>
      <input type="text" name="firstName" placeholder="Prénom*" ref={register({ required: true })}/>

      <label htmlFor="lastName">
        <span className="sr-only">Nom :</span>
      </label>
      <input type="text" name="lastName" placeholder="Nom*" ref={register({ required: true })}/>
      <div className="col-12 p-0">
      <div className="row">
        <div className="col-lg-6">
          <label htmlFor="dateNaissance">Date de naissance :*</label>
          <input
            type="date"
            name="dateNaissance"
            ref={register({ required: true })}
          />
        </div>
        <div className="col-lg-6">
          <label htmlFor="departementNaissance">Département de naissance :</label>
          <input type="text" 
            name="departementNaissance" 
            placeholder="ex: 75"
            ref={register({
                required: false,
                valueAsNumber: true,
                maxLength: {
                  value: 2,
                  message: 'Saisir les 2 premiers chiffres du département',
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Chiffres uniquement autorisés',
                }
              })}
          />
          {errors.departementNaissance && <span className="form-error" role="alert">{parse(errors.departementNaissance.message)}</span>}
        </div>
      </div>
      </div>



      <label htmlFor="nir">
        <span className="sr-only">N° sécurité sociale :</span>
      </label>
      {typeBeneficiaire != "CHILD" &&
      <input 
      type="text" 
      name="nir" 
      placeholder="N° sécurité sociale*"
      ref={register({
         required: true,
         pattern: {
          value: /^[0-9]+$/,
          message: 'Chiffres uniquement autorisés',
        }
        })}/>
      }
      {errors.nir && <span className="form-error" role="alert">{parse(errors.nir.message)}</span>}
      </div>
    </div>   
    </>
  );
}

export default BeneficiaryChange;
import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import AccordionGutenberg from '../../Assets/AccordionGutenberg';
import parse from "html-react-parser";
import useCavomData from "../../Hook/useCavomData";

function FormulaireInscriptionAffilieEmployeur({ setComposantSelect, statut, postData, setEmailForNewValidation }) {
  const { sendCavomData } = useCavomData();
  // const { register, handleSubmit, formState: { isSubmitting }, errors, watch } = useForm();
  const {
    register,
    handleSubmit,
    watch,
    formState,
    formState: { errors },
  } = useForm();
  const { isSubmitting } = formState;



  const [postErrors, setPostErrors] = useState();
  

  const password = useRef({});
  password.current = watch("password", "");

  const email = useRef({});
  email.current = watch("email", "");

  const onSubmit = async (data) => {
    const dateAnniv = new Date(data.dateNaissance);

    const formatDateAnniv = dateAnniv.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });


    const creationCount = {
      idCavom: data.idCavom.trim(),
      lastName: data.name.trim(),
      firstName: data.prenom.trim(),
      email: data.email.trim(),
      username: data.idCavom.trim(),
      nir: data.nir.trim(),
      password: data.password.trim(),
      birthDate: formatDateAnniv.trim(),
      phoneNumber: data.telephone.trim(),
    };

    try {
      const response = await sendCavomData("api/accounts", creationCount);
      
      if (response.com6responsestatus == 202){
        setComposantSelect('SendScreen')
      }

      if (response.message) {
        setPostErrors(response.message);
      }
    } catch (e) {
      if (e.message) {
        console.log(e)
        // redirection sur l'écran de notification associé au code d'erreur
        if ((e.code == 1001) || (e.code == 1013)){
          setEmailForNewValidation(e.data)
          setComposantSelect('AlreadyExistScreen')
        }else if(e.code == 1002){
          setEmailForNewValidation(e.data)
          setComposantSelect('AlreadyExistNonActiveScreen')
        }else{
          setComposantSelect('FormScreen')
        }
        setPostErrors(e.message);
      } else {
        console.log(e)
        setPostErrors("Une erreur inattendue est survenue");
      }
    }
  };

  let info_inscription = "";
  let deja_compte = "";

  if (postData) {
    info_inscription = postData.ACF.aide_inscription_affilie;
    deja_compte = postData.ACF.deja_compte_affilie;
  }
  useEffect(() => {
    AccordionGutenberg();
  });

  return (
    <section
      className={
        statut === "employeur"
          ? "page-inscription-employeur page-employeur"
          : "page-inscription-affilie page-affilie"
      }
    >
      <div className="bandeau-header">
        <div
          className="container"
          style={
            statut === "employeur"
              ? {
                  backgroundImage:
                    "url(images/bandeau-connexion-employeur-2.jpg)",
                }
              : {
                  backgroundImage: "url(images/bandeau-connexion-affilie.jpg)",
                }
          }
        >
          <div className="row">
            {statut === "employeur" ? (
              <>
                <h1>Employeur</h1>
              </>
            ) : (
              <>
                <h1>Affilié</h1>
              </>
            )}
          </div>
        </div>
      </div>

      <div id="inscription-portail" className="container cavom-center-content">
        <div className="row">
          <div className="connexion-portail col-md-5">
            <h2>Première connexion</h2>

            {postErrors && (
              <p className="alert alert-danger" role="alert">
                { postErrors }
              </p>
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
              <label>
                <span className="sr-only">Identifiant CAVOM :</span>
              </label>

              <input
                type="text"
                name="idCavom"
                placeholder="Identifiant CAVOM OMxxxxx*"
                ref={register({
                  required: true,
                  pattern:  {
                    value: /^\+?OM[0-9]{14}/,
                    message: "Veuillez saisir correctement l’identifiant CAVOM"
                  }
                })}
              />
                 {errors.idCavom && <p><span className="form-error" role="alert">{parse(errors.idCavom.message)}</span></p>}

              <label>
                <span className="sr-only">Nom :</span>
              </label>

              <input
                type="text"
                name="name"
                placeholder="Nom*"
                ref={register({
                   required: true,
                   pattern: {
                    value: /^\+?[A-Za-z -]*$/,
                    message: 'Veuillez saisir correctement le nom (Les caractères accentués ne sont pas autorisés)',
                   }
                  })}
              />
              {errors.name && <p><span className="form-error" role="alert">{parse(errors.name.message)}</span></p>}

              <label>
                <span className="sr-only">Prénom :</span>
              </label>

              <input
                type="text"
                name="prenom"
                placeholder="Prénom*"
                ref={register({
                   required: true,
                   pattern: {
                    value: /^\+?[A-Za-z -]*$/,
                    message: 'Veuillez saisir correctement le prénom (Les caractères accentués ne sont pas autorisés)'
                   }
                  })}
              />
              {errors.prenom && <p><span className="form-error" role="alert">{parse(errors.prenom.message)}</span></p>}

              <label>Date de naissance :*</label>

              <input
                type="date"
                name="dateNaissance"
                placeholder="Date de naissance"
                ref={register({ required: true })}
              />

              <label>
                <span className="sr-only">Adresse mél :</span>
              </label>

              <input
                type="text"
                name="email"
                autoComplete="new-password"
                placeholder="Mél*"
                ref={register({
                  required: true,

                  pattern:  {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i,
                    message: email.current + " n'est pas une adresse mél valide"
                  }
                })}
              />
              {errors.email && <span className="form-error" role="alert">{parse(errors.email.message)}</span>}

              <label>
                <span className="sr-only">Confirmez votre email :</span>
              </label>

              <input
                type="text"
                name="email_repeat"
                placeholder="Confirmer votre mél*"
                autoComplete="new-password"
                ref={register({
                  required: true,
                  validate: (value) =>
                    value === email.current || "L'adresse mél de confirmation doit être identique",
                })}
              />

              {errors.email_repeat && (
                <p className="form-error" role="alert">{errors.email_repeat.message}</p>
              )}

              <label>
                <span className="sr-only">Téléphone :</span>
              </label>

              <input
                type="tel"
                name="telephone"
                placeholder="Téléphone*"
                ref={register({
                   required: true,
                   pattern: {
                    value: /^\+?[0-9]{10,14}$/,
                    message: 'Veuillez saisir correctement un numéro de téléphone',
                   }
                  })}
              />

              {errors.telephone && <span className="form-error" role="alert">{parse(errors.telephone.message)}</span>}

              <label>
                <span className="sr-only">Sécurité sociale :</span>
              </label>
                <input
                  type="text"
                  name="nir"
                  placeholder="N° sécurité sociale sans clé*"
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^\+?[A-Za-z0-9]{13}$/,
                      message: 'Le numéro de sécurité sociale saisi est invalide',
                     }
                  })}
                />
              {errors.nir && <span className="form-error" role="alert">{parse(errors.nir.message)}</span>}

              <label>
                <span className="sr-only">Mot de passe :</span>
              </label>

              <input
                type="password"
                name="password"
                autoComplete="new-password"
                placeholder="Mot de passe*"
                ref={register({
                  required: true,
                  pattern: {
                   value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/,
                   message: "Votre mot de passe doit contenir au moins : <ul><li>1 majuscule</li> <li>1 minuscule</li> <li>1 chiffre</li> <li>1 caractère spécial</li> <li>et au minimum 8 caractères au total</li></ul>"
                  } 
                 })}
              />
              {errors.password && <span className="form-error" role="alert">{parse(errors.password.message)}</span>}

              <label>
                <span className="sr-only">Confirmer votre mot de passe :</span>
              </label>

              <input
                type="password"
                name="password_repeat"
                placeholder="Confirmer votre mot de passe*"
                ref={register({
                  required: true,
                  validate: (value) =>
                    value === password.current ||
                    "Le mot de passe de confirmation n'est pas identique",
                })}
              />

              {errors.password_repeat && (
                <p className="form-error" role="alert">{errors.password_repeat.message}</p>
              )}
                 <label htmlFor="curgpd">* Champ obligatoire</label>
              <div className="checkboxes-wrapper">
                <input
                  type="checkbox"
                  id="curgpd"
                  name="curgpd"
                  ref={register({
                    required: "Veuillez accepter les conditions d’utilisation"
                  })}
                />


                <label htmlFor="curgpd">Conditions d'utilisation + RGPD</label>
              {errors.curgpd && (
                <p className="form-error" role="alert">{errors.curgpd.message}</p>
              )}
              </div>

              {isSubmitting &&
                <p className="alert-success pt-2 pb-2 pr-3 pl-3">Envoie en cours...</p>
              }   

              {statut === "employeur" ? (
                <>
                  <input
                    type="submit"
                    className="cavom-button green-button"
                    disabled={isSubmitting}
                    value="Demande d'inscription"
                  />
                </>
              ) : (
                <>
                  <input
                    type="submit"
                    className="cavom-button"
                    disabled={isSubmitting}
                    value="Demande d'inscription"
                  />
                </>
              )}
          
            </form>
          </div>

          {info_inscription && (
            <div className="aide-inscription offset-md-2 col-md-5">
              <h2 className="help-icon">Aide</h2>

            {postData && postData.ACF && postData.ACF.faq_inscription &&
            <div className="faq-wrapper">
            {
            postData.ACF.faq_inscription.map((item, i) => {

              return(                     
                <article key={i} className="faq-result">
                <div className="wp-block-pb-accordion-item c-accordion__item js-accordion-item" data-initially-open="false" data-click-to-close="true" data-auto-close="false" data-scroll="false" data-scroll-offset="0">
                <h3 id={`at-${item.ID}`} className="c-accordion__title js-accordion-controller" role="button" tabIndex="0" aria-controls={`ac-${item.ID}`} aria-expanded="false" dangerouslySetInnerHTML={{ __html: item.post_title}} />
                <div id={`ac-${item.ID}`} className="c-accordion__content" hidden="hidden"><p dangerouslySetInnerHTML={{ __html: item.post_content}} />
                </div>
                </div>
                </article> 
              )              
            })
            }   
            </div>
            }

              <div
                className="aide-affilie"
                dangerouslySetInnerHTML={{ __html: info_inscription }}
              />
            </div>
          )}

          <div className="compte-existant col-12 bt-dotted">
            <h2>vous avez déjà un compte</h2>

            <div dangerouslySetInnerHTML={{ __html: deja_compte }} />

            {statut === "employeur" ? (
              <Link to="/login/employeur" className="cavom-button green-button">
                Se connecter
              </Link>
            ) : (
              <Link to="/login/affilie" className="cavom-button">
                Se connecter
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default FormulaireInscriptionAffilieEmployeur;

export default function useWpData() {
  async function fetchData(endpoint, options = {}) {
    const res = await fetch(
      `https://wordpress.cavom.fr/wp-json/wp/v2/${endpoint}`,
      options
    );
    // const res = await fetch(
    //   ` https://kube.cavom.lan/wordpress-rec/wp-json/wp/v2/${endpoint}`,
    //   options
    // );

    return res.json();
  }

  /**
   * Renvoie les données d'une page WP
   * 
   * @param {*} endpoint 
   * @param {*} options 
   * 
   * @returns {Promise}
   */
  async function fetchWpPages(endpoint = '', options = {}) {
    return await fetchData(`pages/${endpoint}`, options);
  }

  /**
   * Renvoi les données d'un post WP
   * 
   * @param {*} endpoint 
   * @param {*} options 
   * 
   * @returns {Promise}
   */
  async function fetchWpPosts(endpoint = '', options = {}) {
    return await fetchData(`posts/${endpoint}`, options);
  }

  /**
   * Renvoi les données d'un post WP
   * 
   * @param {*} endpoint 
   * @param {*} options 
   * 
   * @returns {Promise}
   */
  async function fetchAllWpPosts(endpoint = '', options = {}) {
    let allWpPostsJson = []
    let npage = 1    
    let tempFetch = await fetchData(`posts?per_page=100`)
    allWpPostsJson = tempFetch
    if (tempFetch.length > 0){
      while (tempFetch.length > 0){
        npage++;
        tempFetch = await fetchData(`posts?per_page=100&page=${npage}`);
        if (tempFetch.length > 0){
          allWpPostsJson = allWpPostsJson.concat(tempFetch)
        }

      }
    }
    return allWpPostsJson;
  }

  /**
   * Renvoi les données de l'API WP
   * 
   * @param {*} endpoint 
   * @param {*} options 
   * 
   * @returns {Promise}
   */
  async function fetchWpBase(endpoint = '', options = {}) {
    return await fetchData(`${endpoint}`, options);
  }

  return {
    fetchWpPages,
    fetchWpPosts,
    fetchWpBase,
    fetchAllWpPosts
  };
}

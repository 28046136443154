import React from 'react';
import { Link } from "react-router-dom";

function Footer(){
  return(
    <footer className='footer'>
      <div className="container">
        <div className="row">
          <div className="block-ft footer-logo col-md-2">
            <Link to="/" className="home-link"><img src="images/cropped-logo.png" alt="Portail Cavom" /></Link>
          </div>
          <div className="block-ft footer-adresse-tel col-md-3">
            <h3>ADRESSE</h3>
            <p>26 boulevard Malesherbes<br/>
            75008 Paris</p>

            <h3 className="tel">TÉLÉPHONE</h3>
            <p>du lundi au vendredi,</p>
            <p>de 9h30 à 12h30</p>
            <p className="block-ft footer-tel">01 85 55 36 37</p>
          </div>
          <div className="block-ft footer-rs col-md-3">
          {/*

            <h3>SUIVEZ NOUS</h3>
            <ul>
              <li className="footer-fb"><a href="#0"><span className="sr-only">Facebook</span></a></li>
              <li className="footer-tw"><a href="#0"><span className="sr-only">Twitter</span></a></li>
              <li className="footer-yt"><a href="#0"><span className="sr-only">Youtube</span></a></li>
            </ul>
        */}  </div>
          <nav className="block-ft footer-menu col-md-4">
            <ul>
              <li><Link to="/page/253">CONTACT</Link></li>
              {/* <li><Link to="/#">ACCESSIBILITÉ</Link></li> */}
              <li><Link to="/page/239">MENTIONS LÉGALES</Link></li>
              <li><Link to="/page/3">POLITIQUE DE CONFIDENTIALITÉ</Link></li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
// fichier écran validation du compte par mail utilisateur
import React, { useState } from "react";
import { Link } from "react-router-dom";
import useCavomData from "../../Hook/useCavomData";



function ValidationMailUser({apitoken, setComposantSelect, composantSelect }) {

  const [postErrors, setPostErrors] = useState();
  const { fetchCavomData } = useCavomData();
  
  const handleValidationMail = async () => {
    try {
          const response = await fetchCavomData(`api/t?q=${apitoken}`)
    
          if (response.com6responsestatus == 200){
            setComposantSelect('ScreenUserValide')
          }
    
          if (response.message) {
            setPostErrors(response.message);
          }
        } catch (e) {
          window.scroll({
            top: 200,
            left: 0,
            behavior: 'smooth',
          });
          if (e.message) {
            console.log(e)
            setPostErrors(e.message);
          } else {
            console.log(e)
            setPostErrors("Une erreur inattendue est survenue");
          }
        }
      }


  return(
    <>
    {(composantSelect == 'FormScreen')
    ? <div className="col-12 form-validation-mail cavom-center-content">
      {postErrors && (
        <p className="alert alert-danger" role="alert">
          { postErrors }
        </p>
      )}
      <h2>Pour valider votre compte, cliquez sur le bouton ci-dessous</h2>
      <button onClick={handleValidationMail} className="cavom-button">Validation du compte</button>
    </div>
    : (composantSelect == 'ScreenUserValide') &&
      <div className="cavom-center-content">
        <h2>Votre compte a bien été validé</h2>
        <Link to='/' className='link-accueil cavom-button'>Retourner à l'accueil du site</Link>
      </div>
    }

    </>
  )

}

export default ValidationMailUser;

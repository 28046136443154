// fichier écran modification de mot de passe user connecté
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";


import useWpData from "../Hook/useWpData";
import FormModifPasswordProfil from "./ModifPassword/FormModifPasswordProfil";

function ModifPassword() {

  const profil_id = "55";
  const { fetchWpPages } = useWpData();  
  const [postData, setPostData] = useState("");  
  const [composantSelect, setComposantSelect] = useState('FormScreen');
  
  let aideModifPassword = "";
  let faqModifPassword = [];

  if (postData && postData.ACF) {
    if (postData.ACF.aide_modif_password){
      aideModifPassword = postData.ACF.aide_modif_password
    }
    if (postData.ACF.faq_modif_password){    
    faqModifPassword = postData.ACF.faq_modif_password
    }
  }

  useEffect(async () => {
    setPostData(await fetchWpPages(profil_id));
  }, []);


  return(
    <>
    <div className="bandeau-header">
      <div className="container">
        <div className="row">
          <h1>Mot de passe</h1>
        </div>
      </div>
    </div>
    <div className="container">
    {(composantSelect == 'FormScreen')
    ? <FormModifPasswordProfil setComposantSelect={setComposantSelect} aideModifPassword={aideModifPassword} faqModifPassword={faqModifPassword} />
    : <>
      {composantSelect == 'SendScreen' &&
      <>
      <div className="cavom-center-content">
        <div className="row">
          <div className="col-12">
            <h3>Votre demande a bien été envoyée.</h3>
            <p>Votre mot de passe a bien été modifié.</p>
            <Link to="/" className="link-accueil cavom-button">Retourner à l'accueil du site</Link>
          </div>
        </div>
      </div> 
      </>   
      }      
      </>
    }
    </div>
    </>
  )

}

export default ModifPassword;

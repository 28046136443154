import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function TableCotiPrevYear ({data, openPdfYearBlob, waitingSpinner}) {
  
  const frenchFormatter = new Intl.NumberFormat("fr-FR", {minimumFractionDigits: 2});

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  
  let arrayTables = [];
  let temporaryTable = [];
  const dataLength = data.length;

  data.map( (item,i) => {
    temporaryTable.push(item);
    if (((i+1) % 5 == 0) || ((i+1) == dataLength) ){
      arrayTables.push(temporaryTable);
      temporaryTable=[];
    }
  })
  
  return (
    <>
    
    <Slider {...settings}>
                    
        {
        arrayTables && arrayTables.map( (items, is) => {
        return (
            <React.Fragment key={is}>
              <table>
                <thead>
                  <tr>
                  <th>Année</th>
                    <th>Montant appelé</th>
                    <th>Solde restant dû</th>
                    <th>Télécharger</th>
                  </tr>
                </thead>
                <tbody>
              {items.map((item,i) => {
                if (item.year != new Date().getFullYear()) {

                    return (
                        <tr key={i}>
                        <td>{item.year}</td>
                        <td>{frenchFormatter.format(item.amountDue)} €</td>
                        <td>{frenchFormatter.format(item.amountStillDue)} €</td>
                        <td>
                          {item.year &&
                          <a className={waitingSpinner == item.year ? "dl-pdf" : "download-icon dl-pdf"} docuid={item.year} onClick={openPdfYearBlob} download><span className="sr-only">Télécharger</span></a>
                          }
                          {waitingSpinner == item.year &&
                          <div className="spinner-border" role="status">
                          < span className="sr-only">Chargement...</span>
                          </div>
                          }
                        </td>
                        </tr>
                    )
                  }
              })}
              </tbody>
            </table>
          </React.Fragment>
        )
        
       })
    }
    </Slider>
    </>   
  )
}

export default TableCotiPrevYear;